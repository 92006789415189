import React from 'react';
import PropTypes from 'prop-types';
import styles from './OnlineServices.module.css';
import localizations from '../../localizations/localizations';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';
import Services from '../Services/Services';

function OnlineServices(props) {

    const {servicesRef, activeLanguage, authorizedToken, isAuthorized} = props;
    const {checkAuthorizedToken, login} = props;

    return (
        <div className={styles.OnlineServices}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.onlineServicesTitle}
                    </Headline>
                </div>
                <Services checkAuthorizedToken={checkAuthorizedToken}
                          ref={servicesRef}
                          activeLanguage={activeLanguage}
                          authorizedToken={authorizedToken}
                          isAuthorized={isAuthorized}
                          login={login}/>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Help}>
                    <Row horizontal={true}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}>
                        {localizations.onlineServicesHelp}
                    </Row>
                </div>
                <div className={styles.Table}>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.onlineServicesTableRowName1}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                    {localizations.onlineServicesTableRowContent1Part1}
                                </a>
                            </Row>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                    {localizations.onlineServicesTableRowContent1Part2}
                                </a>
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.onlineServicesTableRowName2}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentMargin={'0 0 25px'}>
                                <a className={styles.Link}
                                   href={`mailto:${process.env.REACT_APP_ACSK_MAIL}`}>
                                    {localizations.onlineServicesTableRowContent2}
                                </a>
                            </Row>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.onlineServicesTitle}
                    </Headline>
                </div>
                <Services checkAuthorizedToken={checkAuthorizedToken}
                          ref={servicesRef}
                          activeLanguage={activeLanguage}
                          authorizedToken={authorizedToken}
                          isAuthorized={isAuthorized}
                          login={login}/>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Help}>
                    <Row horizontal={true}
                         contentFontSize={'var(--subheader2-font-size)'}
                         contentLineHeight={'var(--subheader2-line-height)'}>
                        {localizations.onlineServicesHelp}
                    </Row>
                </div>
                <div className={styles.Table}>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.onlineServicesTableRowName1}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                    {localizations.onlineServicesTableRowContent1Part1}
                                </a>
                            </Row>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                    {localizations.onlineServicesTableRowContent1Part2}
                                </a>
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.onlineServicesTableRowName2}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentMargin={'0 0 25px'}>
                                <a className={styles.Link}
                                   href={`mailto:${process.env.REACT_APP_ACSK_MAIL}`}>
                                    {localizations.onlineServicesTableRowContent2}
                                </a>
                            </Row>
                        </div>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.onlineServicesTitle}
                    </Headline>
                </div>
                <Services checkAuthorizedToken={checkAuthorizedToken}
                          ref={servicesRef}
                          activeLanguage={activeLanguage}
                          authorizedToken={authorizedToken}
                          isAuthorized={isAuthorized}
                          login={login}/>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Help}>
                    <Row horizontal={true}>
                        {localizations.onlineServicesHelp}
                    </Row>
                </div>
                <div className={styles.Table}>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.onlineServicesTableRowName1}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            <a className={styles.Link}
                               href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                {localizations.onlineServicesTableRowContent1Part1}
                            </a>
                        </Row>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            <a className={styles.Link}
                               href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                {localizations.onlineServicesTableRowContent1Part2}
                            </a>
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.onlineServicesTableRowName2}
                        </Headline>
                        <Row disabledIcon={true}
                             contentMargin={'0 0 10px'}>
                            <a className={styles.Link}
                               href={`mailto:${process.env.REACT_APP_ACSK_MAIL}`}>
                                {localizations.onlineServicesTableRowContent2}
                            </a>
                        </Row>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

OnlineServices.propTypes = {
    checkAuthorizedToken: PropTypes.func.isRequired,
    servicesRef: PropTypes.object.isRequired,
    activeLanguage: PropTypes.string.isRequired,
    authorizedToken: PropTypes.string,
    isAuthorized: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
}

export default OnlineServices;
