import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Menu.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as AuthorizedUserIcon} from './authorizedUserIcon.svg';
import {ReactComponent as UnauthorizedUserIcon} from './unauthorizedUserIcon.svg';
import {ReactComponent as MenuIcon} from './menuIcon.svg';
import {ReactComponent as ArrowIcon} from './arrowIcon.svg';
import {ReactComponent as CloseIcon} from './closeIcon.svg';
import {useOutsideClickDetector} from '../../hooks/useOutsideClickDetector';
import * as pagePaths from '../../paths/pagePaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function Menu(props) {
    const {isAuthorized = false, languages, activeLanguage, authorizedUser} = props;
    const {checkAuthorizedToken, setLanguage, login, logout} = props;
    const frameRef = useRef(null);
    useOutsideClickDetector(frameRef, () => {
        setIsOpen(false);
        checkAuthorizedToken();
    });
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={styles.Menu}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    isAuthorized
                        ? <AuthorizedUserIcon className={styles.Icon}
                                              onClick={() => {
                                                  setIsOpen(!isOpen);
                                                  checkAuthorizedToken();
                                              }}/>
                        : <UnauthorizedUserIcon className={styles.Icon}
                                                onClick={() => {
                                                    setIsOpen(!isOpen);
                                                    checkAuthorizedToken();
                                                }}/>
                }
                {
                    isOpen && <ArrowIcon className={styles.Arrow}/>
                }
                {
                    isOpen && <div ref={frameRef}
                                   className={styles.Frame}>
                        <div className={styles.Authorization}>
                            {
                                isAuthorized
                                    ? <div className={styles.AuthorizedActions}>
                                        <div className={styles.Action}>
                                            <Headline contentMargin={'15px 15px 0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {authorizedUser}
                                            </Headline>
                                        </div>
                                        <div className={styles.Row}>
                                            <Row topLine={true}
                                                 disabledIcon={true}/>
                                        </div>
                                        <div className={styles.Action}>
                                            <Link className={styles.Link}
                                                  to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}
                                                  onClick={() => setIsOpen(false)}>
                                                {localizations.menuMyCertificatesPageLink}
                                            </Link>
                                        </div>
                                        <div className={styles.Action}>
                                            <div className={styles.Logout}
                                                 onClick={() => {
                                                     logout();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogout}
                                            </div>
                                        </div>
                                    </div>
                                    : <div className={styles.UnauthorizedActions}>
                                        <div className={styles.Action}>
                                            <div className={styles.Login}
                                                 onClick={() => {
                                                     login();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogin}
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <MenuIcon className={styles.Icon}
                          onClick={() => {
                              setIsOpen(true);
                              checkAuthorizedToken();
                          }}/>
                {
                    isOpen && <div className={styles.Blur}
                                   onClick={() => {
                                       setIsOpen(false);
                                       checkAuthorizedToken();
                                   }}/>
                }
                {
                    isOpen && <div className={styles.Frame}>
                        <div className={styles.Control}>
                            <div className={styles.Languages}>
                                {
                                    languages.map((value, index) => {
                                        return (
                                            <div key={index}
                                                 className={styles.Container}>
                                                <div
                                                    className={value.key === activeLanguage ? styles.ActiveLanguage : styles.InactiveLanguage}
                                                    onClick={() => {
                                                        setLanguage(value.key);
                                                        checkAuthorizedToken();
                                                    }}>
                                                    {value.name}
                                                </div>
                                                {
                                                    index + 1 !== languages.length && <div className={styles.Line}>
                                                        |
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className={styles.Close}>
                                <CloseIcon className={styles.Icon}
                                           onClick={() => {
                                               setIsOpen(false);
                                               checkAuthorizedToken();
                                           }}/>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Links}>
                            <Link className={styles.Link}
                                  to={pagePaths.CERTIFICATES_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuCertificatesPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.NORMATIVE_BASE_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuNormativeBasePageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.DOCUMENTS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuDocumentsPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.SMARTID_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuSmartIDPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.ONLINE_SERVICES_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuOnlineServicesPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.FREQUENTLY_ASKED_QUESTIONS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuFrequentlyAskedQuestionsLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.CONTACTS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuContactsPageLink}
                            </Link>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Authorization}>
                            {
                                isAuthorized
                                    ? <AuthorizedUserIcon className={styles.Icon}/>
                                    : <UnauthorizedUserIcon className={styles.Icon}/>
                            }
                            {
                                isAuthorized
                                    ? <div className={styles.AuthorizedActions}>
                                        <div className={styles.Action}>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body1-bold-font-weight)'}
                                                      contentFontSize={'var(--body1-bold-font-size)'}
                                                      contentLineHeight={'var(--body1-bold-line-height)'}>
                                                {authorizedUser}
                                            </Headline>
                                        </div>
                                        <div className={styles.Action}>
                                            <Link className={styles.Link}
                                                  to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}
                                                  onClick={() => setIsOpen(false)}>
                                                {localizations.menuMyCertificatesPageLink}
                                            </Link>
                                        </div>
                                        <div className={styles.Action}>
                                            <div className={styles.Logout}
                                                 onClick={() => {
                                                     logout();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogout}
                                            </div>
                                        </div>
                                    </div>
                                    : <div className={styles.UnauthorizedActions}>
                                        <div className={styles.Action}>
                                            <div className={styles.Login}
                                                 onClick={() => {
                                                     login();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogin}
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <MenuIcon className={styles.Icon}
                          onClick={() => {
                              setIsOpen(true);
                              checkAuthorizedToken();
                          }}/>
                {
                    isOpen && <div className={styles.Blur}
                                   onClick={() => {
                                       setIsOpen(false);
                                       checkAuthorizedToken();
                                   }}/>
                }
                {
                    isOpen && <div className={styles.Frame}>
                        <div className={styles.Control}>
                            <div className={styles.Languages}>
                                {
                                    languages.map((value, index) => {
                                        return (
                                            <div key={index}
                                                 className={styles.Container}>
                                                <div
                                                    className={value.key === activeLanguage ? styles.ActiveLanguage : styles.InactiveLanguage}
                                                    onClick={() => {
                                                        setLanguage(value.key);
                                                        checkAuthorizedToken();
                                                    }}>
                                                    {value.name}
                                                </div>
                                                {
                                                    index + 1 !== languages.length && <div className={styles.Line}>
                                                        |
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className={styles.Close}>
                                <CloseIcon className={styles.Icon}
                                           onClick={() => {
                                               setIsOpen(false);
                                               checkAuthorizedToken();
                                           }}/>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Links}>
                            <Link className={styles.Link}
                                  to={pagePaths.CERTIFICATES_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuCertificatesPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.NORMATIVE_BASE_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuNormativeBasePageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.DOCUMENTS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuDocumentsPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.SMARTID_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuSmartIDPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.ONLINE_SERVICES_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuOnlineServicesPageLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.FREQUENTLY_ASKED_QUESTIONS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuFrequentlyAskedQuestionsLink}
                            </Link>
                            <Link className={styles.Link}
                                  to={pagePaths.CONTACTS_PAGE_PATH}
                                  onClick={() => setIsOpen(false)}>
                                {localizations.menuContactsPageLink}
                            </Link>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Authorization}>
                            {
                                isAuthorized
                                    ? <AuthorizedUserIcon className={styles.Icon}/>
                                    : <UnauthorizedUserIcon className={styles.Icon}/>
                            }
                            {
                                isAuthorized
                                    ? <div className={styles.AuthorizedActions}>
                                        <div className={styles.Action}>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body1-bold-font-weight)'}
                                                      contentFontSize={'var(--body1-bold-font-size)'}
                                                      contentLineHeight={'var(--body1-bold-line-height)'}>
                                                {authorizedUser}
                                            </Headline>
                                        </div>
                                        <div className={styles.Action}>
                                            <Link className={styles.Link}
                                                  to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}
                                                  onClick={() => setIsOpen(false)}>
                                                {localizations.menuMyCertificatesPageLink}
                                            </Link>
                                        </div>
                                        <div className={styles.Action}>
                                            <div className={styles.Logout}
                                                 onClick={() => {
                                                     logout();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogout}
                                            </div>
                                        </div>
                                    </div>
                                    : <div className={styles.UnauthorizedActions}>
                                        <div className={styles.Action}>
                                            <div className={styles.Login}
                                                 onClick={() => {
                                                     login();
                                                     setIsOpen(false);
                                                 }}>
                                                {localizations.menuLogin}
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            </MobileOnlyView>
        </div>
    );
}

Menu.propTypes = {
    checkAuthorizedToken: PropTypes.func.isRequired,
    isAuthorized: PropTypes.bool,
    languages: PropTypes.array,
    activeLanguage: PropTypes.string,
    setLanguage: PropTypes.func,
    authorizedUser: PropTypes.string,
    login: PropTypes.func,
    logout: PropTypes.func,
}

export default Menu;
