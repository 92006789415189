import React from 'react';
import PropTypes from 'prop-types';
import styles from './TechWorks.module.css';
import localizations from '../../localizations/localizations';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';

function TechWorks(props) {
    const {techWorks} = props;
    return (
        <div className={styles.TechWorks}>
            <BrowserView viewClassName={styles.Browser}>
                <Headline contentMargin={'0'}
                          contentFontWeight={'var(--headline3-font-weight)'}
                          contentFontSize={'var(--headline3-font-size)'}
                          contentLineHeight={'var(--headline3-line-height)'}>
                    {`${localizations.techWorks1} ${techWorks.from} ${localizations.techWorks2} ${techWorks.to} ${localizations.techWorks3}`}
                </Headline>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <Headline contentMargin={'0'}
                          contentFontWeight={'var(--headline3-font-weight)'}
                          contentFontSize={'var(--headline3-font-size)'}
                          contentLineHeight={'var(--headline3-line-height)'}>
                    {`${localizations.techWorks1} ${techWorks.from} ${localizations.techWorks2} ${techWorks.to} ${localizations.techWorks3}`}
                </Headline>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <Headline contentMargin={'0'}
                          contentFontWeight={'var(--headline3-font-weight)'}
                          contentFontSize={'var(--headline3-font-size)'}
                          contentLineHeight={'var(--headline3-line-height)'}>
                    {`${localizations.techWorks1} ${techWorks.from} ${localizations.techWorks2} ${techWorks.to} ${localizations.techWorks3}`}
                </Headline>
            </MobileOnlyView>
        </div>
    );
}

TechWorks.propTypes = {
    techWorks: PropTypes.object.isRequired,
}

export default TechWorks;
