import React from 'react';
import {CustomView, isBrowser, isTablet, isMobileOnly} from 'react-device-detect';

function BrowserView({children, ...props}) {
    return (
        <CustomView condition={isBrowser && !isTablet && !isMobileOnly} {...props}>
            {children}
        </CustomView>
    );
}

export default BrowserView;
