import React from 'react';
import PropTypes from 'prop-types';
import styles from './RevokedCertificate.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as DownloadIcon} from './downloadIcon.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function RevokedCertificate(props) {
    const {full, url, name, startDate, nextDate, serial} = props;
    return (
        <div className={styles.RevokedCertificate}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.Card}>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <a href={url}
                               className={styles.Download}>
                                <DownloadIcon className={styles.Icon}/>
                            </a>
                        </div>
                        <div className={styles.Right}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {full
                                    ? localizations.revokedCertificateFullName.replaceAll('{name}', name)
                                    : localizations.revokedCertificatePartName.replaceAll('{name}', name)
                                }
                            </Headline>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateStartDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {startDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateNextDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {nextDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateSerial}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {serial}
                            </Row>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.Card}>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <a href={url}
                               className={styles.Download}>
                                <DownloadIcon className={styles.Icon}/>
                            </a>
                        </div>
                        <div className={styles.Right}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {full
                                    ? localizations.revokedCertificateFullName.replaceAll('{name}', name)
                                    : localizations.revokedCertificatePartName.replaceAll('{name}', name)
                                }
                            </Headline>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateStartDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {startDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateNextDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {nextDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateSerial}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {serial}
                            </Row>
                        </div>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.Card}>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <a href={url}
                               className={styles.Download}>
                                <DownloadIcon className={styles.Icon}/>
                            </a>
                        </div>
                        <div className={styles.Right}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {full
                                    ? localizations.revokedCertificateFullName.replaceAll('{name}', name)
                                    : localizations.revokedCertificatePartName.replaceAll('{name}', name)
                                }
                            </Headline>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateStartDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {startDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateNextDate}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {nextDate}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Left}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--caption-bold-font-weight)'}
                                      contentFontSize={'var(--caption-bold-font-size)'}
                                      contentLineHeight={'var(--caption-bold-line-height)'}
                                      contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                      contentColor={'var(--050-black)'}>
                                {localizations.revokedCertificateSerial}
                            </Headline>
                        </div>
                        <div className={styles.Right}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}>
                                {serial}
                            </Row>
                        </div>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

RevokedCertificate.propTypes = {
    full: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    nextDate: PropTypes.string.isRequired,
    serial: PropTypes.string.isRequired,
}

export default RevokedCertificate;
