import React, {useState, useEffect} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './AcskCertificates.module.css';
import localizations from '../../localizations/localizations';
import * as backPaths from '../../paths/backPaths';
import {ReactComponent as DownloadIcon} from './downloadIcon.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import AcskCertificate from '../AcskCertificate/AcskCertificate';
import Row from '../Row/Row';

function AcskCertificates(props) {
    const {errorGetAcskCertificates} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [acskCertificates, setAcskCertificates] = useState([]);
    useEffect(() => {
        axios.get(backPaths.GET_ACSK_CERTIFICATES_BACK_PATH)
            .then(response => {
                console.log('Get acsk certificates response = ', response);
                setIsLoading(false);
                setAcskCertificates(response.data.sort((first, second) => first.index - second.index));
            })
            .catch(error => {
                console.log('Get acsk certificates response = ', error.response);
                setIsLoading(false);
                errorGetAcskCertificates(500, error.response.headers.requestid);
            });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (acskCertificates.length > 0) {
            console.log('ACSK certificates = ', acskCertificates);
        }
    }, [acskCertificates]);
    return (
        <div className={styles.AcskCertificates}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.acskCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Download}>
                        <a href={'https://acsk.privatbank.ua/arch/download/PB-2023.p7b'}
                           className={styles.Container}>
                            <div className={styles.Left}>
                                <DownloadIcon className={styles.Icon}/>
                            </div>
                            <div className={styles.Right}>
                                <div className={styles.Text}>
                                    {localizations.acskCertificatesDownloadAll}
                                </div>
                            </div>
                        </a>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Row}>
                        {
                            acskCertificates.map((acskCertificate, index) => {
                                return (
                                    <AcskCertificate key={index}
                                                     url={acskCertificate.url}
                                                     name={acskCertificate.name}
                                                     startDate={acskCertificate.startDate}
                                                     endDate={acskCertificate.endDate}
                                                     serial={acskCertificate.serial}/>
                                );
                            })
                        }
                    </div>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.acskCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Download}>
                        <a href={'https://acsk.privatbank.ua/arch/download/PB-2023.p7b'}
                           className={styles.Container}>
                            <div className={styles.Left}>
                                <DownloadIcon className={styles.Icon}/>
                            </div>
                            <div className={styles.Right}>
                                <div className={styles.Text}>
                                    {localizations.acskCertificatesDownloadAll}
                                </div>
                            </div>
                        </a>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Row}>
                        {
                            acskCertificates.map((acskCertificate, index) => {
                                return (
                                    <AcskCertificate key={index}
                                                     url={acskCertificate.url}
                                                     name={acskCertificate.name}
                                                     startDate={acskCertificate.startDate}
                                                     endDate={acskCertificate.endDate}
                                                     serial={acskCertificate.serial}/>
                                );
                            })
                        }
                    </div>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.acskCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Download}>
                        <a href={'https://acsk.privatbank.ua/arch/download/PB-2023.p7b'}
                           className={styles.Container}>
                            <div className={styles.Left}>
                                <DownloadIcon className={styles.Icon}/>
                            </div>
                            <div className={styles.Right}>
                                <div className={styles.Text}>
                                    {localizations.acskCertificatesDownloadAll}
                                </div>
                            </div>
                        </a>
                    </div>
                }
                {
                    !isLoading && acskCertificates.length > 0 && <div className={styles.Row}>
                        {
                            acskCertificates.map((acskCertificate, index) => {
                                return (
                                    <AcskCertificate key={index}
                                                     url={acskCertificate.url}
                                                     name={acskCertificate.name}
                                                     startDate={acskCertificate.startDate}
                                                     endDate={acskCertificate.endDate}
                                                     serial={acskCertificate.serial}/>
                                );
                            })
                        }
                    </div>
                }
            </MobileOnlyView>
        </div>
    );
}

AcskCertificates.propTypes = {
    errorGetAcskCertificates: PropTypes.func.isRequired,
}

export default AcskCertificates;
