import {useEffect} from 'react';

function useOutsideClickDetector(ref, outsideClickCallback) {
    useEffect(() => {
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClickCallback && outsideClickCallback();
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ref, outsideClickCallback]);
}

export {useOutsideClickDetector};
