import React, {forwardRef, useState, useRef, useImperativeHandle, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Services.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as GenerationIcon} from './generationIcon.svg';
import {ReactComponent as SignIcon} from './signIcon.svg';
import {ReactComponent as VerificationCertificateIcon} from './verificationCertificateIcon.svg';
import {ReactComponent as VerificationSignIcon} from './verificationSignIcon.svg';
import {ReactComponent as UnpublicationIcon} from './unpublicationIcon.svg';
import {ReactComponent as RevocationIcon} from './revocationIcon.svg';
import {ReactComponent as InfoIcon} from './infoIcon.svg';
import * as servicePaths from '../../paths/servicePaths';
import * as pagePaths from '../../paths/pagePaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Row from '../Row/Row';
import Tooltip from '../Tooltip/Tooltip';

const Services = forwardRef((props, ref) => {
    const {activeLanguage, authorizedToken, isAuthorized} = props;
    const {checkAuthorizedToken, login} = props;
    const [frame, setFrame] = useState({
        show: false,
        url: undefined,
    });
    const iframeRef = useRef();
    useImperativeHandle(ref, () => ({
        close() {
            setFrame({
                show: false,
                url: undefined,
            });
        },
        openGenerationService() {
            if (isAuthorized) {
                checkAuthorizedToken(() => iframe(servicePaths.GENERATION_SERVICE_PATH), () => doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH)));
            } else {
                doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH));
            }
        }
    }));

    function iframe(url) {
        if (frame.show) {
            if (frame.url === url) {
                setFrame({
                    show: false,
                    url: undefined,
                });
            } else {
                setFrame({
                    show: true,
                    url: url,
                });
            }
        } else {
            setFrame({
                show: true,
                url: url,
            });
        }
    }

    function doLogin(callback) {
        setFrame({
            show: false,
            url: undefined,
        });
        login(callback);
    }

    function stopPropagation(event) {
        event.stopPropagation();
    }

    useEffect(() => {
        if (!isAuthorized) {
            setFrame({
                show: false,
                url: undefined,
            });
        }
    }, [isAuthorized]);
    useEffect(() => {
        if (frame && frame.show && activeLanguage) {
            console.log('Frame URL = ', frame.url.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken));
            setTimeout(() => {
                iframeRef.current && iframeRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 1000);
        }
    }, [frame, isAuthorized, activeLanguage, authorizedToken]);
    return (
        <div className={styles.Services}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.Container}>
                    <div className={styles.Service}
                         onClick={() => {
                             if (isAuthorized) {
                                 checkAuthorizedToken(() => iframe(servicePaths.GENERATION_SERVICE_PATH), () => doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH)));
                             } else {
                                 doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH));
                             }
                         }}>
                        <GenerationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesGeneration}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=9AQqwb7_f4s'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.SIGN_SERVICE_PATH);
                         }}>
                        <SignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=0viJYKxVBgo'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <UnpublicationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesUnpublication}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=eAkoNsBi25o'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <RevocationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesRevocation}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=78cq-c2fbDk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_SIGN_SERVICE_PATH);
                         }}>
                        <VerificationSignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=dn8-UumullE'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_CERTIFICATE_SERVICE_PATH);
                         }}>
                        <VerificationCertificateIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationCertificate}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=hZ8ElhNboAk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                </div>
                {
                    frame.show && activeLanguage && <div className={styles.Display}>
                        <iframe ref={iframeRef}
                                src={frame.url.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken)}
                                className={frame.url === servicePaths.GENERATION_SERVICE_PATH ? styles.FrameNarrow : styles.Frame}
                                title={'service'}/>
                    </div>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.Container}>
                    <div className={styles.Service}
                         onClick={() => {
                             if (isAuthorized) {
                                 checkAuthorizedToken(() => iframe(servicePaths.GENERATION_SERVICE_PATH), () => doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH)));
                             } else {
                                 doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH));
                             }
                         }}>
                        <GenerationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesGeneration}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=9AQqwb7_f4s'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.SIGN_SERVICE_PATH);
                         }}>
                        <SignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=0viJYKxVBgo'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <UnpublicationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesUnpublication}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=eAkoNsBi25o'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <RevocationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesRevocation}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=78cq-c2fbDk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_SIGN_SERVICE_PATH);
                         }}>
                        <VerificationSignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=dn8-UumullE'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_CERTIFICATE_SERVICE_PATH);
                         }}>
                        <VerificationCertificateIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationCertificate}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=hZ8ElhNboAk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                </div>
                {
                    frame.show && activeLanguage && <div className={styles.Display}>
                        <iframe ref={iframeRef}
                                src={frame.url.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken)}
                                className={frame.url === servicePaths.GENERATION_SERVICE_PATH ? styles.FrameNarrow : styles.Frame}
                                title={'service'}/>
                    </div>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.Container}>
                    <div className={styles.Service}
                         onClick={() => {
                             if (isAuthorized) {
                                 checkAuthorizedToken(() => iframe(servicePaths.GENERATION_SERVICE_PATH), () => doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH)));
                             } else {
                                 doLogin(() => iframe(servicePaths.GENERATION_SERVICE_PATH));
                             }
                         }}>
                        <GenerationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesGeneration}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=9AQqwb7_f4s'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.SIGN_SERVICE_PATH);
                         }}>
                        <SignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=0viJYKxVBgo'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <UnpublicationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesUnpublication}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=eAkoNsBi25o'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <Link className={styles.Service}
                          to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                        <RevocationIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesRevocation}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=78cq-c2fbDk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </Link>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_SIGN_SERVICE_PATH);
                         }}>
                        <VerificationSignIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationSign}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=dn8-UumullE'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                    <div className={styles.Service}
                         onClick={() => {
                             iframe(servicePaths.VERIFICATION_CERTIFICATE_SERVICE_PATH);
                         }}>
                        <VerificationCertificateIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentDisplay={'block'}
                                 contentMargin={'0 15px'}
                                 contentFontSize={'var(--body2-font-size)'}
                                 contentLineHeight={'var(--body2-line-height)'}
                                 contentColor={'var(--black)'}>
                                {localizations.servicesVerificationCertificate}
                            </Row>
                        </div>
                        <Tooltip messages={[localizations.servicesInfoTooltip]}
                                 placement={'top'}>
                            <a href={'https://www.youtube.com/watch?v=hZ8ElhNboAk'}
                               target={'_blank'}
                               rel={'noopener noreferrer'}
                               className={styles.Info}
                               onClick={stopPropagation}>
                                <InfoIcon className={styles.Icon}/>
                            </a>
                        </Tooltip>
                    </div>
                </div>
                {
                    frame.show && activeLanguage && <div className={styles.Display}>
                        <iframe ref={iframeRef}
                                src={frame.url.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken)}
                                className={frame.url === servicePaths.GENERATION_SERVICE_PATH ? styles.FrameNarrow : styles.Frame}
                                title={'service'}/>
                    </div>
                }
            </MobileOnlyView>
        </div>
    );
});

Services.propTypes = {
    checkAuthorizedToken: PropTypes.func.isRequired,
    activeLanguage: PropTypes.string.isRequired,
    authorizedToken: PropTypes.string,
    isAuthorized: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
}

export default Services;
