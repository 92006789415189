import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.css';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

function Dropdown(props) {
    const {values, activeValue = values[0], optionBackgroundColor} = props;
    const {setValue} = props;

    function getContentStyles() {
        const contentStyles = {};
        if (optionBackgroundColor) {
            contentStyles.background = optionBackgroundColor;
        }
        return contentStyles;
    }

    return (
        <div className={styles.Dropdown}>
            <BrowserView viewClassName={styles.Browser}>
                <select className={styles.Select}
                        value={activeValue}
                        onChange={(event) => setValue(event.target.value)}>
                    {
                        values.map((value, index) => {
                            return (
                                <option key={index}
                                        style={getContentStyles()}
                                        className={styles.Option}
                                        value={value.key}>
                                    {value.name}
                                </option>
                            );
                        })
                    }
                </select>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <select className={styles.Select}
                        value={activeValue}
                        onChange={(event) => setValue(event.target.value)}>
                    {
                        values.map((value, index) => {
                            return (
                                <option key={index}
                                        style={getContentStyles()}
                                        className={styles.Option}
                                        value={value.key}>
                                    {value.name}
                                </option>
                            );
                        })
                    }
                </select>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <select className={styles.Select}
                        value={activeValue}
                        onChange={(event) => setValue(event.target.value)}>
                    {
                        values.map((value, index) => {
                            return (
                                <option key={index}
                                        style={getContentStyles()}
                                        className={styles.Option}
                                        value={value.key}>
                                    {value.name}
                                </option>
                            );
                        })
                    }
                </select>
            </MobileOnlyView>
        </div>
    );
}

Dropdown.propTypes = {
    values: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired,
    activeValue: PropTypes.string,
    optionBackgroundColor: PropTypes.string,
}

export default Dropdown;
