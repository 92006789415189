import React from 'react';
import styles from './NormativeBase.module.css';
import localizations from '../../localizations/localizations';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function NormativeBase() {
    return (
        <div className={styles.NormativeBase}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.normativeBaseTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.normativeBaseSection1}
                    </Headline>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=505/98'}>
                        {localizations.normativeBaseSection1Document1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection2}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/2657-12'}>
                        {localizations.normativeBaseSection2Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/80/94-%D0%B2%D1%80'}>
                        {localizations.normativeBaseSection2Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2121-14/print1443083301440858'}>
                        {localizations.normativeBaseSection2Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=851-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection2Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2297-17/page'}>
                        {localizations.normativeBaseSection2Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2939-17'}>
                        {localizations.normativeBaseSection2Document6}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/222-19'}>
                        {localizations.normativeBaseSection2Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2155-19'}>
                        {localizations.normativeBaseSection2Document8}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection3}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/821-2016-%D0%BF'}>
                        {localizations.normativeBaseSection3Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/356-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/821-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/914-2018-п'}>
                        {localizations.normativeBaseSection3Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1215-2018-%D0%BF?lang=uk'}>
                        {localizations.normativeBaseSection3Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/856-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1068-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/193-2020-%D0%BF'}>
                        {localizations.normativeBaseSection3Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-zabezpechennya-bezperervnogo-nadannya-kvalifikovanih-elektronnih-dovirchih-poslug-u-razi-zamini-nadavacha-takih-poslug-345290420'}>
                        {localizations.normativeBaseSection3Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-vikoristannya-viddalenogo-kvalifikovanogo-elektronnogo-pidpisu-smart-diya-i020920-785'}>
                        {localizations.normativeBaseSection3Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanov-kabinetu-ministriv-ukrayini-vid-9-grudnya-2020-r-1236-i-vid-29-chervnya-2021-r-677-229'}>
                        {localizations.normativeBaseSection3Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/deyaki-pitannya-zabezpechennya-bezperebijnogo-funkcionuvannya-sistemi-nadannya-elektronnih-dovirchih-poslug-300'}>
                        {localizations.normativeBaseSection3Document17}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanovi-kabinetu-ministriv-ukrayini-vid-17-bereznya-2022-r-300-617-240522'}>
                        {localizations.normativeBaseSection3Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/764-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1062-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7_1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection4}
                    </Headline>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0955-07'}>
                        {localizations.normativeBaseSection4Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0095500-17'}>
                        {localizations.normativeBaseSection4Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_19092019_116'}>
                        {localizations.normativeBaseSection4Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24122019_153'}>
                        {localizations.normativeBaseSection4Document7}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0019500-20#Text'}>
                        {localizations.normativeBaseSection4Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0032500-20#Text'}>
                        {localizations.normativeBaseSection4Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24032023_31'}>
                        {localizations.normativeBaseSection4Document10}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_27032023_35'}>
                        {localizations.normativeBaseSection4Document11}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection5}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/z0820-07'}>
                        {localizations.normativeBaseSection5Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0729-07'}>
                        {localizations.normativeBaseSection5Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0862-07'}>
                        {localizations.normativeBaseSection5Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0651-08'}>
                        {localizations.normativeBaseSection5Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0367-12'}>
                        {localizations.normativeBaseSection5Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/v1277323-12'}>
                        {localizations.normativeBaseSection5Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/rada/show/v0215519-13#Text'}>
                        {localizations.normativeBaseSection5Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon5.rada.gov.ua/laws/show/z1421-14'}>
                        {localizations.normativeBaseSection5Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0051-16#Text'}>
                        {localizations.normativeBaseSection5Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0123-19'}>
                        {localizations.normativeBaseSection5Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://czo.gov.ua/download/normativedocs/Nakaz_vtrata_chynnosti.pdf'}>
                        {localizations.normativeBaseSection5Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0385-20#Text'}>
                        {localizations.normativeBaseSection5Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://cip.gov.ua/ua/news/rekomendaciyi-administraciyi-derzhavnoyi-sluzhbi-specialnogo-zv-yazku-ta-zakhistu-informaciyi-ukrayini-do-vstanovlennya-rivniv-bezpeki-ta-mekhanizmiv-bezpeki-primishen-kvalifikovanogo-nadavacha-elektronnikh-dovirchikh-poslug'}>
                        {localizations.normativeBaseSection5Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1272-20#Text'}>
                        {localizations.normativeBaseSection5Document18}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/rozyasnennya_udoskonaleniy_EP.pdf'}>
                        {localizations.normativeBaseSection5Document19}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection6}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=852-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection6Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/680-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/903-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document3}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1452-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Explained.pdf'}>
                        {localizations.normativeBaseSection6Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1453-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1454-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0104-05'}>
                        {localizations.normativeBaseSection6Document8}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0568-06'}>
                        {localizations.normativeBaseSection6Document9}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0914-07'}>
                        {localizations.normativeBaseSection6Document10}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0113-08'}>
                        {localizations.normativeBaseSection6Document11}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1052-10'}>
                        {localizations.normativeBaseSection6Document12}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1398-12'}>
                        {localizations.normativeBaseSection6Document13}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0108-13'}>
                        {localizations.normativeBaseSection6Document14}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z2227-13'}>
                        {localizations.normativeBaseSection6Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0829500-15'}>
                        {localizations.normativeBaseSection4Document2}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0378500-16'}>
                        {localizations.normativeBaseSection6Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0078500-17'}>
                        {localizations.normativeBaseSection4Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/749-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/775-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1221-18'}>
                        {localizations.normativeBaseSection6Document17}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1253-18'}>
                        {localizations.normativeBaseSection6Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/992-2018-%D0%BF'}>
                        {localizations.normativeBaseSection6Document21}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1462-18'}>
                        {localizations.normativeBaseSection6Document19}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/60-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/546-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1147-19?fbclid=IwAR371Qa_e-Ji2lZq1amw9WYdZwTbvS3NlAkYELYmw01p4UGtH1-3Y8NjrF8'}>
                        {localizations.normativeBaseSection5Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1172-19'}>
                        {localizations.normativeBaseSection6Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0151500-19#Text'}>
                        {localizations.normativeBaseSection4Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0668-20#Text'}>
                        {localizations.normativeBaseSection5Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0719-20#Text'}>
                        {localizations.normativeBaseSection5Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0798-20#Text'}>
                        {localizations.normativeBaseSection5Document16}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1039-20#Text'}>
                        {localizations.normativeBaseSection5Document17}
                    </Row>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.normativeBaseTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.normativeBaseSection1}
                    </Headline>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=505/98'}>
                        {localizations.normativeBaseSection1Document1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection2}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/2657-12'}>
                        {localizations.normativeBaseSection2Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/80/94-%D0%B2%D1%80'}>
                        {localizations.normativeBaseSection2Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2121-14/print1443083301440858'}>
                        {localizations.normativeBaseSection2Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=851-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection2Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2297-17/page'}>
                        {localizations.normativeBaseSection2Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2939-17'}>
                        {localizations.normativeBaseSection2Document6}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/222-19'}>
                        {localizations.normativeBaseSection2Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2155-19'}>
                        {localizations.normativeBaseSection2Document8}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection3}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/821-2016-%D0%BF'}>
                        {localizations.normativeBaseSection3Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/356-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/821-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/914-2018-п'}>
                        {localizations.normativeBaseSection3Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1215-2018-%D0%BF?lang=uk'}>
                        {localizations.normativeBaseSection3Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/856-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1068-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/193-2020-%D0%BF'}>
                        {localizations.normativeBaseSection3Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-zabezpechennya-bezperervnogo-nadannya-kvalifikovanih-elektronnih-dovirchih-poslug-u-razi-zamini-nadavacha-takih-poslug-345290420'}>
                        {localizations.normativeBaseSection3Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-vikoristannya-viddalenogo-kvalifikovanogo-elektronnogo-pidpisu-smart-diya-i020920-785'}>
                        {localizations.normativeBaseSection3Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanov-kabinetu-ministriv-ukrayini-vid-9-grudnya-2020-r-1236-i-vid-29-chervnya-2021-r-677-229'}>
                        {localizations.normativeBaseSection3Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/deyaki-pitannya-zabezpechennya-bezperebijnogo-funkcionuvannya-sistemi-nadannya-elektronnih-dovirchih-poslug-300'}>
                        {localizations.normativeBaseSection3Document17}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanovi-kabinetu-ministriv-ukrayini-vid-17-bereznya-2022-r-300-617-240522'}>
                        {localizations.normativeBaseSection3Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/764-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1062-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7_1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection4}
                    </Headline>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0955-07'}>
                        {localizations.normativeBaseSection4Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0095500-17'}>
                        {localizations.normativeBaseSection4Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_19092019_116'}>
                        {localizations.normativeBaseSection4Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24122019_153'}>
                        {localizations.normativeBaseSection4Document7}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0019500-20#Text'}>
                        {localizations.normativeBaseSection4Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0032500-20#Text'}>
                        {localizations.normativeBaseSection4Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24032023_31'}>
                        {localizations.normativeBaseSection4Document10}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_27032023_35'}>
                        {localizations.normativeBaseSection4Document11}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection5}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/z0820-07'}>
                        {localizations.normativeBaseSection5Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0729-07'}>
                        {localizations.normativeBaseSection5Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0862-07'}>
                        {localizations.normativeBaseSection5Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0651-08'}>
                        {localizations.normativeBaseSection5Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0367-12'}>
                        {localizations.normativeBaseSection5Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/v1277323-12'}>
                        {localizations.normativeBaseSection5Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/rada/show/v0215519-13#Text'}>
                        {localizations.normativeBaseSection5Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon5.rada.gov.ua/laws/show/z1421-14'}>
                        {localizations.normativeBaseSection5Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0051-16#Text'}>
                        {localizations.normativeBaseSection5Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0123-19'}>
                        {localizations.normativeBaseSection5Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://czo.gov.ua/download/normativedocs/Nakaz_vtrata_chynnosti.pdf'}>
                        {localizations.normativeBaseSection5Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0385-20#Text'}>
                        {localizations.normativeBaseSection5Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://cip.gov.ua/ua/news/rekomendaciyi-administraciyi-derzhavnoyi-sluzhbi-specialnogo-zv-yazku-ta-zakhistu-informaciyi-ukrayini-do-vstanovlennya-rivniv-bezpeki-ta-mekhanizmiv-bezpeki-primishen-kvalifikovanogo-nadavacha-elektronnikh-dovirchikh-poslug'}>
                        {localizations.normativeBaseSection5Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1272-20#Text'}>
                        {localizations.normativeBaseSection5Document18}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/rozyasnennya_udoskonaleniy_EP.pdf'}>
                        {localizations.normativeBaseSection5Document19}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection6}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=852-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection6Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/680-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/903-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document3}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1452-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Explained.pdf'}>
                        {localizations.normativeBaseSection6Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1453-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1454-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0104-05'}>
                        {localizations.normativeBaseSection6Document8}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0568-06'}>
                        {localizations.normativeBaseSection6Document9}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0914-07'}>
                        {localizations.normativeBaseSection6Document10}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0113-08'}>
                        {localizations.normativeBaseSection6Document11}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1052-10'}>
                        {localizations.normativeBaseSection6Document12}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1398-12'}>
                        {localizations.normativeBaseSection6Document13}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0108-13'}>
                        {localizations.normativeBaseSection6Document14}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z2227-13'}>
                        {localizations.normativeBaseSection6Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0829500-15'}>
                        {localizations.normativeBaseSection4Document2}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0378500-16'}>
                        {localizations.normativeBaseSection6Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0078500-17'}>
                        {localizations.normativeBaseSection4Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/749-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/775-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1221-18'}>
                        {localizations.normativeBaseSection6Document17}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1253-18'}>
                        {localizations.normativeBaseSection6Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/992-2018-%D0%BF'}>
                        {localizations.normativeBaseSection6Document21}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1462-18'}>
                        {localizations.normativeBaseSection6Document19}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/60-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/546-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1147-19?fbclid=IwAR371Qa_e-Ji2lZq1amw9WYdZwTbvS3NlAkYELYmw01p4UGtH1-3Y8NjrF8'}>
                        {localizations.normativeBaseSection5Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1172-19'}>
                        {localizations.normativeBaseSection6Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0151500-19#Text'}>
                        {localizations.normativeBaseSection4Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0668-20#Text'}>
                        {localizations.normativeBaseSection5Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0719-20#Text'}>
                        {localizations.normativeBaseSection5Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0798-20#Text'}>
                        {localizations.normativeBaseSection5Document16}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1039-20#Text'}>
                        {localizations.normativeBaseSection5Document17}
                    </Row>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.normativeBaseTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.normativeBaseSection1}
                    </Headline>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=505/98'}>
                        {localizations.normativeBaseSection1Document1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection2}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/2657-12'}>
                        {localizations.normativeBaseSection2Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/80/94-%D0%B2%D1%80'}>
                        {localizations.normativeBaseSection2Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2121-14/print1443083301440858'}>
                        {localizations.normativeBaseSection2Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=851-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection2Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/2297-17/page'}>
                        {localizations.normativeBaseSection2Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2939-17'}>
                        {localizations.normativeBaseSection2Document6}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/222-19'}>
                        {localizations.normativeBaseSection2Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon3.rada.gov.ua/laws/show/2155-19'}>
                        {localizations.normativeBaseSection2Document8}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection3}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/821-2016-%D0%BF'}>
                        {localizations.normativeBaseSection3Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/356-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/821-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/914-2018-п'}>
                        {localizations.normativeBaseSection3Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1215-2018-%D0%BF?lang=uk'}>
                        {localizations.normativeBaseSection3Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/856-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1068-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/193-2020-%D0%BF'}>
                        {localizations.normativeBaseSection3Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-zabezpechennya-bezperervnogo-nadannya-kvalifikovanih-elektronnih-dovirchih-poslug-u-razi-zamini-nadavacha-takih-poslug-345290420'}>
                        {localizations.normativeBaseSection3Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-realizaciyu-eksperimentalnogo-proektu-shchodo-vikoristannya-viddalenogo-kvalifikovanogo-elektronnogo-pidpisu-smart-diya-i020920-785'}>
                        {localizations.normativeBaseSection3Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanov-kabinetu-ministriv-ukrayini-vid-9-grudnya-2020-r-1236-i-vid-29-chervnya-2021-r-677-229'}>
                        {localizations.normativeBaseSection3Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/deyaki-pitannya-zabezpechennya-bezperebijnogo-funkcionuvannya-sistemi-nadannya-elektronnih-dovirchih-poslug-300'}>
                        {localizations.normativeBaseSection3Document17}
                    </Row>
                    <Row topLine={true}
                         link={'https://www.kmu.gov.ua/npas/pro-vnesennya-zmin-do-postanovi-kabinetu-ministriv-ukrayini-vid-17-bereznya-2022-r-300-617-240522'}>
                        {localizations.normativeBaseSection3Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/764-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1062-2024-%D0%BF#Text'}>
                        {localizations.normativeBaseSection3Document7_1}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection4}
                    </Headline>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0955-07'}>
                        {localizations.normativeBaseSection4Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0095500-17'}>
                        {localizations.normativeBaseSection4Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_19092019_116'}>
                        {localizations.normativeBaseSection4Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24122019_153'}>
                        {localizations.normativeBaseSection4Document7}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0019500-20#Text'}>
                        {localizations.normativeBaseSection4Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0032500-20#Text'}>
                        {localizations.normativeBaseSection4Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_24032023_31'}>
                        {localizations.normativeBaseSection4Document10}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://bank.gov.ua/ua/legislation/Resolution_27032023_35'}>
                        {localizations.normativeBaseSection4Document11}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection5}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/z0820-07'}>
                        {localizations.normativeBaseSection5Document1}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0729-07'}>
                        {localizations.normativeBaseSection5Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0862-07'}>
                        {localizations.normativeBaseSection5Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0651-08'}>
                        {localizations.normativeBaseSection5Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0367-12'}>
                        {localizations.normativeBaseSection5Document5}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/v1277323-12'}>
                        {localizations.normativeBaseSection5Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/rada/show/v0215519-13#Text'}>
                        {localizations.normativeBaseSection5Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon5.rada.gov.ua/laws/show/z1421-14'}>
                        {localizations.normativeBaseSection5Document8}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0051-16#Text'}>
                        {localizations.normativeBaseSection5Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0123-19'}>
                        {localizations.normativeBaseSection5Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://czo.gov.ua/download/normativedocs/Nakaz_vtrata_chynnosti.pdf'}>
                        {localizations.normativeBaseSection5Document12}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0385-20#Text'}>
                        {localizations.normativeBaseSection5Document13}
                    </Row>
                    <Row topLine={true}
                         link={'https://cip.gov.ua/ua/news/rekomendaciyi-administraciyi-derzhavnoyi-sluzhbi-specialnogo-zv-yazku-ta-zakhistu-informaciyi-ukrayini-do-vstanovlennya-rivniv-bezpeki-ta-mekhanizmiv-bezpeki-primishen-kvalifikovanogo-nadavacha-elektronnikh-dovirchikh-poslug'}>
                        {localizations.normativeBaseSection5Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1272-20#Text'}>
                        {localizations.normativeBaseSection5Document18}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/rozyasnennya_udoskonaleniy_EP.pdf'}>
                        {localizations.normativeBaseSection5Document19}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.normativeBaseSection6}
                    </Headline>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=852-15&p=1274774604685720'}>
                        {localizations.normativeBaseSection6Document1}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/680-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document2}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/laws/show/903-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document3}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1452-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document4}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Explained.pdf'}>
                        {localizations.normativeBaseSection6Document5}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1453-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/1454-2004-%D0%BF'}>
                        {localizations.normativeBaseSection6Document7}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon1.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0104-05'}>
                        {localizations.normativeBaseSection6Document8}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/cgi-bin/laws/main.cgi?nreg=z0568-06'}>
                        {localizations.normativeBaseSection6Document9}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z0914-07'}>
                        {localizations.normativeBaseSection6Document10}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0113-08'}>
                        {localizations.normativeBaseSection6Document11}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1052-10'}>
                        {localizations.normativeBaseSection6Document12}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon2.rada.gov.ua/laws/show/z1398-12'}>
                        {localizations.normativeBaseSection6Document13}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z0108-13'}>
                        {localizations.normativeBaseSection6Document14}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon4.rada.gov.ua/laws/show/z2227-13'}>
                        {localizations.normativeBaseSection6Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0829500-15'}>
                        {localizations.normativeBaseSection4Document2}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0378500-16'}>
                        {localizations.normativeBaseSection6Document16}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0078500-17'}>
                        {localizations.normativeBaseSection4Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/749-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document3}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/775-2018-%D0%BF'}>
                        {localizations.normativeBaseSection3Document4}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1221-18'}>
                        {localizations.normativeBaseSection6Document17}
                    </Row>
                    <Row topLine={true}
                         link={'http://zakon.rada.gov.ua/laws/show/z1253-18'}>
                        {localizations.normativeBaseSection6Document18}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/992-2018-%D0%BF'}>
                        {localizations.normativeBaseSection6Document21}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1462-18'}>
                        {localizations.normativeBaseSection6Document19}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/60-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document9}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/546-2019-%D0%BF'}>
                        {localizations.normativeBaseSection3Document10}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1147-19?fbclid=IwAR371Qa_e-Ji2lZq1amw9WYdZwTbvS3NlAkYELYmw01p4UGtH1-3Y8NjrF8'}>
                        {localizations.normativeBaseSection5Document11}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1172-19'}>
                        {localizations.normativeBaseSection6Document20}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/v0151500-19#Text'}>
                        {localizations.normativeBaseSection4Document6}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0668-20#Text'}>
                        {localizations.normativeBaseSection5Document14}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0719-20#Text'}>
                        {localizations.normativeBaseSection5Document15}
                    </Row>
                    <Row topLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z0798-20#Text'}>
                        {localizations.normativeBaseSection5Document16}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://zakon.rada.gov.ua/laws/show/z1039-20#Text'}>
                        {localizations.normativeBaseSection5Document17}
                    </Row>
                </div>
            </MobileOnlyView>
        </div>
    );
}

export default NormativeBase;
