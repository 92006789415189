import React, {useRef} from 'react';
import {Switch, Route, Redirect, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Certificates.module.css';
import localizations from '../../localizations/localizations';
import * as pagePaths from '../../paths/pagePaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';
import ClientsCertificates from '../ClientsCertificates/ClientsCertificates';
import AcskCertificates from '../AcskCertificates/AcskCertificates';
import RevokedCertificates from '../RevokedCertificates/RevokedCertificates';
import PersonalCertificates from '../PersonalCertificates/PersonalCertificates';

function Certificates(props) {
    const {personalCertificatesRef, isAuthorized, authorizedToken, activeLanguage} = props;
    const {
        errorGetClientCertificates,
        errorGetAcskCertificates,
        errorGetRevokedCertificates,
        login,
        checkAuthorizedToken,
        errorGetPersonalCertificates,
        errorGetCertificates,
        errorGetPdf,
        errorRevocationRequest,
        errorUnpublicationRequest,
        errorGenerationRequest,
    } = props;
    const titleRef = useRef();
    const menuRef = useRef();
    const bottomRowRef = useRef();

    function openCertificatesNavigation() {
        if (titleRef.current) {
            titleRef.current.hidden = false;
        }
        if (menuRef.current) {
            menuRef.current.hidden = false;
        }
        if (bottomRowRef.current) {
            bottomRowRef.current.hidden = false;
        }
    }

    function closeCertificatesNavigation() {
        titleRef.current.hidden = true;
        menuRef.current.hidden = true;
        bottomRowRef.current.hidden = true;
    }

    return (
        <div className={styles.Certificates}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div ref={titleRef}
                     className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.certificatesTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <div ref={menuRef}
                         className={styles.MenuContainer}>
                        <div className={styles.Menu}>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                    <div ref={bottomRowRef}
                         className={styles.BottomRow}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <Switch>
                        <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                            <ClientsCertificates activeLanguage={activeLanguage}
                                                 openCertificatesNavigation={openCertificatesNavigation}
                                                 closeCertificatesNavigation={closeCertificatesNavigation}
                                                 errorGetClientCertificates={errorGetClientCertificates}
                                                 errorGetCertificates={errorGetCertificates}
                                                 errorGetPdf={errorGetPdf}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                            <AcskCertificates errorGetAcskCertificates={errorGetAcskCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                            <RevokedCertificates errorGetRevokedCertificates={errorGetRevokedCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                            <PersonalCertificates ref={personalCertificatesRef}
                                                  isAuthorized={isAuthorized}
                                                  authorizedToken={authorizedToken}
                                                  activeLanguage={activeLanguage}
                                                  login={login}
                                                  checkAuthorizedToken={checkAuthorizedToken}
                                                  errorGetPersonalCertificates={errorGetPersonalCertificates}
                                                  errorGetCertificates={errorGetCertificates}
                                                  errorGetPdf={errorGetPdf}
                                                  errorRevocationRequest={errorRevocationRequest}
                                                  errorUnpublicationRequest={errorUnpublicationRequest}
                                                  errorGenerationRequest={errorGenerationRequest}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                            <Redirect from={pagePaths.CERTIFICATES_PAGE_PATH}
                                      to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}/>
                        </Route>
                    </Switch>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div ref={titleRef}
                     className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.certificatesTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <div ref={menuRef}
                         className={styles.MenuContainer}>
                        <div className={styles.Menu}>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                    <div ref={bottomRowRef}
                         className={styles.BottomRow}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <Switch>
                        <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                            <ClientsCertificates activeLanguage={activeLanguage}
                                                 openCertificatesNavigation={openCertificatesNavigation}
                                                 closeCertificatesNavigation={closeCertificatesNavigation}
                                                 errorGetClientCertificates={errorGetClientCertificates}
                                                 errorGetCertificates={errorGetCertificates}
                                                 errorGetPdf={errorGetPdf}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                            <AcskCertificates errorGetAcskCertificates={errorGetAcskCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                            <RevokedCertificates errorGetRevokedCertificates={errorGetRevokedCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                            <PersonalCertificates ref={personalCertificatesRef}
                                                  isAuthorized={isAuthorized}
                                                  authorizedToken={authorizedToken}
                                                  activeLanguage={activeLanguage}
                                                  login={login}
                                                  checkAuthorizedToken={checkAuthorizedToken}
                                                  errorGetPersonalCertificates={errorGetPersonalCertificates}
                                                  errorGetCertificates={errorGetCertificates}
                                                  errorGetPdf={errorGetPdf}
                                                  errorRevocationRequest={errorRevocationRequest}
                                                  errorUnpublicationRequest={errorUnpublicationRequest}
                                                  errorGenerationRequest={errorGenerationRequest}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                            <Redirect from={pagePaths.CERTIFICATES_PAGE_PATH}
                                      to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}/>
                        </Route>
                    </Switch>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div ref={titleRef}
                     className={styles.Title}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.certificatesTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <div ref={menuRef}
                         className={styles.MenuContainer}>
                        <div className={styles.Menu}>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                                            {localizations.certificatesClients}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                                            {localizations.certificatesAcsk}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                                            {localizations.certificatesRevoked}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                            <div className={styles.Tab}>
                                <Switch>
                                    <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                        <Link className={styles.Selected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                        <hr className={styles.Line}/>
                                    </Route>
                                    <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                                        <Link className={styles.Unselected}
                                              to={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                                            {localizations.certificatesPersonal}
                                        </Link>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                    <div ref={bottomRowRef}
                         className={styles.BottomRow}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <Switch>
                        <Route path={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}>
                            <ClientsCertificates activeLanguage={activeLanguage}
                                                 openCertificatesNavigation={openCertificatesNavigation}
                                                 closeCertificatesNavigation={closeCertificatesNavigation}
                                                 errorGetClientCertificates={errorGetClientCertificates}
                                                 errorGetCertificates={errorGetCertificates}
                                                 errorGetPdf={errorGetPdf}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_ACSK_PAGE_PATH}>
                            <AcskCertificates errorGetAcskCertificates={errorGetAcskCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_REVOKED_PAGE_PATH}>
                            <RevokedCertificates errorGetRevokedCertificates={errorGetRevokedCertificates}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PERSONAL_PAGE_PATH}>
                            <PersonalCertificates ref={personalCertificatesRef}
                                                  isAuthorized={isAuthorized}
                                                  authorizedToken={authorizedToken}
                                                  activeLanguage={activeLanguage}
                                                  login={login}
                                                  checkAuthorizedToken={checkAuthorizedToken}
                                                  errorGetPersonalCertificates={errorGetPersonalCertificates}
                                                  errorGetCertificates={errorGetCertificates}
                                                  errorGetPdf={errorGetPdf}
                                                  errorRevocationRequest={errorRevocationRequest}
                                                  errorUnpublicationRequest={errorUnpublicationRequest}
                                                  errorGenerationRequest={errorGenerationRequest}/>
                        </Route>
                        <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                            <Redirect from={pagePaths.CERTIFICATES_PAGE_PATH}
                                      to={pagePaths.CERTIFICATES_CLIENTS_PAGE_PATH}/>
                        </Route>
                    </Switch>
                </div>
            </MobileOnlyView>
        </div>
    );
}

Certificates.propTypes = {
    personalCertificatesRef: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    authorizedToken: PropTypes.string,
    activeLanguage: PropTypes.string.isRequired,
    errorGetClientCertificates: PropTypes.func.isRequired,
    errorGetAcskCertificates: PropTypes.func.isRequired,
    errorGetRevokedCertificates: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    checkAuthorizedToken: PropTypes.func.isRequired,
    errorGetPersonalCertificates: PropTypes.func.isRequired,
    errorGetCertificates: PropTypes.func.isRequired,
    errorGetPdf: PropTypes.func.isRequired,
    errorRevocationRequest: PropTypes.func.isRequired,
    errorUnpublicationRequest: PropTypes.func.isRequired,
    errorGenerationRequest: PropTypes.func.isRequired,
}

export default Certificates;
