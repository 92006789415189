import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Error.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as CloseIcon} from './closeIcon.svg';
import {useOutsideClickDetector} from '../../hooks/useOutsideClickDetector';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function Error(props) {
    const {errorCode, requestId} = props;
    const {reset} = props;
    const frameRef = useRef(null);
    useOutsideClickDetector(frameRef, () => reset());
    return (
        <div className={styles.Error}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div className={styles.Header}>
                            <div className={styles.Title}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body1-bold-font-weight)'}
                                          contentFontSize={'var(--body1-bold-font-size)'}
                                          contentLineHeight={'var(--body1-bold-line-height)'}>
                                    {localizations.errorTitle}
                                </Headline>
                            </div>
                            <div className={styles.Close}>
                                <CloseIcon className={styles.Icon}
                                           onClick={() => reset()}/>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Content}>
                            {
                                !localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorCode}: ${errorCode}`}
                                    </Row>
                                </div>
                            }
                            {
                                localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorMessage}: ${localizations[errorCode]}`}
                                    </Row>
                                </div>
                            }
                            {
                                requestId && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorRequestId}: ${requestId}`}
                                    </Row>
                                </div>
                            }
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Recommendation}>
                            <Row horizontal={true}>
                                {localizations.errorRecommendation}
                            </Row>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div className={styles.Header}>
                            <div className={styles.Title}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body1-bold-font-weight)'}
                                          contentFontSize={'var(--body1-bold-font-size)'}
                                          contentLineHeight={'var(--body1-bold-line-height)'}>
                                    {localizations.errorTitle}
                                </Headline>
                            </div>
                            <div className={styles.Close}>
                                <CloseIcon className={styles.Icon}
                                           onClick={() => reset()}/>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Content}>
                            {
                                !localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorCode}: ${errorCode}`}
                                    </Row>
                                </div>
                            }
                            {
                                localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorMessage}: ${localizations[errorCode]}`}
                                    </Row>
                                </div>
                            }
                            {
                                requestId && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorRequestId}: ${requestId}`}
                                    </Row>
                                </div>
                            }
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Recommendation}>
                            <Row horizontal={true}>
                                {localizations.errorRecommendation}
                            </Row>
                        </div>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div className={styles.Header}>
                            <div className={styles.Title}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body1-bold-font-weight)'}
                                          contentFontSize={'var(--body1-bold-font-size)'}
                                          contentLineHeight={'var(--body1-bold-line-height)'}>
                                    {localizations.errorTitle}
                                </Headline>
                            </div>
                            <div className={styles.Close}>
                                <CloseIcon className={styles.Icon}
                                           onClick={() => reset()}/>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Content}>
                            {
                                !localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorCode}: ${errorCode}`}
                                    </Row>
                                </div>
                            }
                            {
                                localizations[errorCode] && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorMessage}: ${localizations[errorCode]}`}
                                    </Row>
                                </div>
                            }
                            {
                                requestId && <div className={styles.Row}>
                                    <Row horizontal={true}>
                                        {`${localizations.errorRequestId}: ${requestId}`}
                                    </Row>
                                </div>
                            }
                        </div>
                        <div className={styles.Row}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                        </div>
                        <div className={styles.Recommendation}>
                            <Row horizontal={true}>
                                {localizations.errorRecommendation}
                            </Row>
                        </div>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

Error.propTypes = {
    errorCode: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
    requestId: PropTypes.string,
}

export default Error;
