import React, {useState, useEffect} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './RevokedCertificates.module.css';
import localizations from '../../localizations/localizations';
import * as backPaths from '../../paths/backPaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import RevokedCertificate from '../RevokedCertificate/RevokedCertificate';
import Row from '../Row/Row';

function RevokedCertificates(props) {
    const {errorGetRevokedCertificates} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [revokedCertificates, setRevokedCertificates] = useState([]);
    useEffect(() => {
        axios.get(backPaths.GET_REVOKED_CERTIFICATES_BACK_PATH)
            .then(response => {
                console.log('Get revoked certificates response = ', response);
                setIsLoading(false);
                setRevokedCertificates(response.data.sort((first, second) => second.index - first.index));
            })
            .catch(error => {
                console.log('Get revoked certificates response = ', error.response);
                setIsLoading(false);
                errorGetRevokedCertificates(400, error.response.headers.requestid);
            });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (revokedCertificates.length > 0) {
            console.log('Revoked certificates = ', revokedCertificates);
        }
    }, [revokedCertificates]);
    return (
        <div className={styles.RevokedCertificates}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.revokedCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && revokedCertificates.map((revokedCertificate, index) => {
                        return (
                            <div key={index}
                                 className={styles.Row}>
                                {
                                    revokedCertificate.full && <RevokedCertificate full={true}
                                                                                   url={revokedCertificate.full.url}
                                                                                   name={revokedCertificate.full.name}
                                                                                   startDate={revokedCertificate.full.startDate}
                                                                                   nextDate={revokedCertificate.full.nextDate}
                                                                                   serial={revokedCertificate.full.serial}/>
                                }
                                {
                                    revokedCertificate.part && <RevokedCertificate full={false}
                                                                                   url={revokedCertificate.part.url}
                                                                                   name={revokedCertificate.part.name}
                                                                                   startDate={revokedCertificate.part.startDate}
                                                                                   nextDate={revokedCertificate.part.nextDate}
                                                                                   serial={revokedCertificate.part.serial}/>
                                }
                            </div>
                        );
                    })
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.revokedCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && revokedCertificates.map((revokedCertificate, index) => {
                        return (
                            <div key={index}
                                 className={styles.Row}>
                                {
                                    revokedCertificate.full && <RevokedCertificate full={true}
                                                                                   url={revokedCertificate.full.url}
                                                                                   name={revokedCertificate.full.name}
                                                                                   startDate={revokedCertificate.full.startDate}
                                                                                   nextDate={revokedCertificate.full.nextDate}
                                                                                   serial={revokedCertificate.full.serial}/>
                                }
                                {
                                    revokedCertificate.part && <RevokedCertificate full={false}
                                                                                   url={revokedCertificate.part.url}
                                                                                   name={revokedCertificate.part.name}
                                                                                   startDate={revokedCertificate.part.startDate}
                                                                                   nextDate={revokedCertificate.part.nextDate}
                                                                                   serial={revokedCertificate.part.serial}/>
                                }
                            </div>
                        );
                    })
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    isLoading && <div className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.revokedCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    !isLoading && revokedCertificates.map((revokedCertificate, index) => {
                        return (
                            <div key={index}
                                 className={styles.Row}>
                                {
                                    revokedCertificate.full && <RevokedCertificate full={true}
                                                                                   url={revokedCertificate.full.url}
                                                                                   name={revokedCertificate.full.name}
                                                                                   startDate={revokedCertificate.full.startDate}
                                                                                   nextDate={revokedCertificate.full.nextDate}
                                                                                   serial={revokedCertificate.full.serial}/>
                                }
                                {
                                    revokedCertificate.part && <RevokedCertificate full={false}
                                                                                   url={revokedCertificate.part.url}
                                                                                   name={revokedCertificate.part.name}
                                                                                   startDate={revokedCertificate.part.startDate}
                                                                                   nextDate={revokedCertificate.part.nextDate}
                                                                                   serial={revokedCertificate.part.serial}/>
                                }
                            </div>
                        );
                    })
                }
            </MobileOnlyView>
        </div>
    );
}

RevokedCertificates.propTypes = {
    errorGetRevokedCertificates: PropTypes.func.isRequired,
}

export default RevokedCertificates;
