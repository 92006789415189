import React, {useEffect, useRef, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import axios from 'axios';
import {deviceDetect} from 'react-device-detect';
import styles from './App.module.css';
import localizations from '../../localizations/localizations';
import queryParser from 'query-string';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import * as resourcePaths from '../../paths/resourcePaths';
import * as pagePaths from '../../paths/pagePaths';
import * as backPaths from '../../paths/backPaths';
import Header from '../Header/Header';
import Intro from '../Intro/Intro';
import Main from '../Main/Main';
import Certificates from '../Certificates/Certificates';
import NormativeBase from '../NormativeBase/NormativeBase';
import Documents from '../Documents/Documents';
import SmartID from '../SmartID/SmartID';
import OnlineServices from '../OnlineServices/OnlineServices';
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';
import Authorization from '../Authorization/Authorization';
import Error from '../Error/Error';
import TagManager from "react-gtm-module/dist/TagManager";

const messageAllowedOrigins = process.env.REACT_APP_MESSAGE_ALLOWED_ORIGINS.split(',');
const gtmId = process.env.REACT_APP_PUBLIC_GTM_ID || "";

function App() {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [language, setLanguage] = useLocalStorage('language', defineLanguage, (language) => language && localizations.setLanguage(language.toLowerCase()), (language) => language && localizations.setLanguage(language.toLowerCase()));
    const [techWorks, setTechWorks] = useLocalStorage('techWorks', {
        enable: false,
        from: '',
        to: '',
        delta: '',
    });
    const [isAuthorized, setIsAuthorized] = useLocalStorage('isAuthorized');
    const [authorization, setAuthorization] = useState({
        show: false,
        callback: undefined,
    });
    const [authorizedToken, setAuthorizedToken] = useLocalStorage('authorizedToken');
    const [authorizedUser, setAuthorizedUser] = useLocalStorage('authorizedUser');
    const [error, setError] = useState({
        show: false,
        errorCode: undefined,
        requestId: undefined,
    });
    const [isAuthorizedByTempToken, setIsAuthorizedByTempToken] = useState(false);
    const servicesRef = useRef();
    const personalCertificatesRef = useRef();
    const tagManagerArgs = {
        gtmId
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    function storage(event) {
        const key = event.key;
        const value = JSON.parse(event.newValue);
        if (key === 'language') {
            setLanguage(value);
        } else if (key === 'techWorks') {
            setTechWorks(value);
        } else if (key === 'isAuthorized') {
            setIsAuthorized(value);
        } else if (key === 'authorizedToken') {
            setAuthorizedToken(value);
        } else if (key === 'authorizedUser') {
            setAuthorizedUser(value);
        }
    }

    function sendGtmEvent(eventName) {
        TagManager.dataLayer({
            dataLayer: {
                gtmId,
                event: eventName
            }
        });
    }

    function message(event) {
        try {
            if (messageAllowedOrigins.includes(event.origin)) {
                if (event.data) {
                    let message;
                    if (typeof event.data === 'string') {
                        message = JSON.parse(event.data);
                    } else {
                        message = event.data;
                    }
                    if (message.action === 'AUTH') {
                        scrollToTop();
                        setIsAuthorized(false);
                        setAuthorizedToken(null);
                        setAuthorizedUser(null);
                        setAuthorization({
                            show: true,
                            callback: undefined,
                        });
                    } else if (message.action === 'RELOAD') {
                        scrollToTop();
                        if (personalCertificatesRef.current) {
                            personalCertificatesRef.current.reload();
                        }
                    } else if (message.action === 'CLOSE') {
                        scrollToTop();
                        if (servicesRef.current) {
                            servicesRef.current.close();
                        }
                    } else if (message.action === 'CLOSE-AND-RELOAD') {
                        scrollToTop();
                        if (servicesRef.current) {
                            servicesRef.current.close();
                        }
                        if (personalCertificatesRef.current) {
                            personalCertificatesRef.current.reload();
                        }
                    } else if (message.action === 'CLOSE-AND-GO-MAIN') {
                        scrollToTop();
                        if (servicesRef.current) {
                            servicesRef.current.close();
                        }
                        history.push(pagePaths.MAIN_PAGE_PATH);
                    } else if (message.action === 'OPEN-GENERATION-SERVICE') {
                        scrollToTop();
                        if (servicesRef.current) {
                            servicesRef.current.close();
                        }
                        history.push(pagePaths.MAIN_PAGE_PATH);
                        if (servicesRef.current) {
                            servicesRef.current.openGenerationService();
                        }
                    } else if (message.action === "GTM-EVENT") {
                        sendGtmEvent(message?.eventName);
                    }
                }
            }
        } catch (ignore) {
        }
    }

    function defineLanguage() {
        const {language} = queryParser.parse(location.search);
        if (language) {
            localizations.setLanguage(language.toString().toLowerCase());
        } else {
            const defaultLanguage = localizations.getAvailableLanguages()[0];
            localizations.setLanguage(defaultLanguage);
        }
        return localizations.getLanguage();
    }

    function defineTechWorks() {
        axios.get(resourcePaths.MAINTAINCE_RESOURCE_PATH)
            .then(response => {
                console.log('Maintaince resource response = ', response);
                const {enable, from, to, delta} = response.data;
                const now = Date.now();
                const dateTo = parseDateTime(to);
                if (now > dateTo) {
                    setTechWorks({
                        enable: false,
                        from: '',
                        to: '',
                        delta: '',
                    });
                } else {
                    const dateFrom = parseDateTime(from) - (Number(delta) * 60 * 60 * 1000);
                    if (now < dateFrom) {
                        setTechWorks({
                            enable: false,
                            from: '',
                            to: '',
                            delta: '',
                        });
                    } else {
                        setTechWorks({
                            enable: enable,
                            from: from,
                            to: to,
                            delta: delta,
                        });
                    }
                }
            })
            .catch(error => {
                console.log('Maintaince resource response = ', error.response);
                setTechWorks({
                    enable: false,
                    from: '',
                    to: '',
                    delta: '',
                });
            });
    }

    function parseDateTime(dateTime) {
        const splitDateTime = dateTime.split(' ');
        const time = splitDateTime[0];
        const date = splitDateTime[1];
        const splitTime = time.split(':');
        const splitDate = date.split('.');
        const hours = Number(splitTime[0]);
        const minutes = Number(splitTime[1]);
        const seconds = 0;
        const milliseconds = 0;
        const day = Number(splitDate[0]);
        const month = Number(splitDate[1]) - 1;
        const year = Number(splitDate[2]);
        return new Date(year, month, day, hours, minutes, seconds, milliseconds).getTime();
    }

    function checkAuthorizedToken(successCallback, failureCallback) {
        const request = {
            token: authorizedToken,
        };
        if (authorizedToken && authorizedUser) {
            axios.post(backPaths.GET_AUTH_STATUS_BACK_PATH, request)
                .then(response => {
                    setIsAuthorized(response.data.authorized);
                    if (response.data.authorized) {
                        successCallback && successCallback();
                    } else {
                        setAuthorizedToken(null);
                        setAuthorizedUser(null);
                        failureCallback && failureCallback();
                    }
                })
                .catch(error => {
                    console.log('Get authorization status response = ', error.response);
                    setIsAuthorized(false);
                    setAuthorizedToken(null);
                    setAuthorizedUser(null);
                    scrollToTop();
                    setError({
                        show: true,
                        errorCode: 300,
                        requestId: error.response.headers.requestid,
                    });
                });
        } else {
            setIsAuthorized(false);
            setAuthorizedToken(null);
            setAuthorizedUser(null);
        }
    }

    function login(callback) {
        scrollToTop();
        setAuthorization({
            show: true,
            callback: callback,
        });
    }

    function logout() {
        setIsAuthorized(false);
        setAuthorization({
            show: false,
            callback: undefined,
        });
        setAuthorizedToken(null);
        setAuthorizedUser(null);
    }


    function getInternalToken(token, callback) {
        setAuthorizedToken(token);
        const request = {
            token: token,
        };
        axios.post(backPaths.GET_INTERNAL_TOKEN_PATH, request)
            .then(response => {
                successAuthorization(response?.data?.token, () => setIsAuthorizedByTempToken(true));
                typeof callback === "function" && callback && callback();
            })
            .catch(error => {
                console.log('Get internal token response = ', error.response);
                scrollToTop();
                setError({
                    show: true,
                    errorCode: 200,
                    requestId: error.response?.headers?.requestId,
                });
            });
    }

    function getWidgetUrl() {
        const domain = window.location.hostname;
        console.log("Domain: ", domain)
        const isTestEnvironment = domain.includes("tst") || domain.includes("localhost");
        return isTestEnvironment ?
            process.env.REACT_APP_P24_LOGIN_WIDGET_TEST_URL :
            process.env.REACT_APP_P24_LOGIN_WIDGET_PROD_URL;
    }

    function successAuthorization(token, callback) {
        setAuthorizedToken(token);
        const request = {
            token: token,
        };
        axios.post(backPaths.GET_AUTH_USER_BACK_PATH, request)
            .then(response => {
                setAuthorizedUser(response?.data?.user);
                setIsAuthorized(true);
                setAuthorizedToken(token);
                typeof callback === "function" && callback && callback();
            })
            .catch(error => {
                console.log('Get authorization user response = ', error.response);
                scrollToTop();
                setError({
                    show: true,
                    errorCode: 200,
                    requestId: error.response?.headers?.requestId,
                });
            });
    }

    function errorAuthorization(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function resetAuthorization() {
        setAuthorization({
            show: false,
            callback: undefined,
        });
    }

    function errorGetClientCertificates(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGetAcskCertificates(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGetRevokedCertificates(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGetPersonalCertificates(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGetCertificates(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGetPdf(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorRevocationRequest(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorUnpublicationRequest(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function errorGenerationRequest(errorCode, requestId) {
        scrollToTop();
        setError({
            show: true,
            errorCode: errorCode,
            requestId: requestId,
        });
    }

    function resetError() {
        setError({
            show: false,
            errorCode: undefined,
            requestId: undefined,
        });
    }

    useEffect(() => {
        scrollToTop();
    }, []);
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        console.log('User agent = ', deviceDetect());
    }, []);
    useEffect(() => {
        window.addEventListener('storage', storage);
        return () => window.removeEventListener('storage', storage);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        window.addEventListener('message', message);
        return () => window.removeEventListener('message', message);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (token) {
            getInternalToken(token);
            searchParams.delete('token');
            history.replace({search: searchParams.toString()});
        }
        // eslint-disable-next-line
    }, [token])

    useEffect(() => {
        const service = searchParams.get('service');
        if (isAuthorized && service === "generation" && isAuthorizedByTempToken) {
            servicesRef.current.openGenerationService();
        }
        // eslint-disable-next-line
    }, [isAuthorizedByTempToken]);

    useEffect(() => {
        defineTechWorks();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        console.log('Location = ', location);
        // eslint-disable-next-line
    }, [location]);
    useEffect(() => {
        if (language) {
            console.log(`Language = ${language}`);
            document.title = localizations.title;
        }
    }, [language]);
    useEffect(() => {
        console.log(`Authorized = ${isAuthorized}`);
    }, [isAuthorized]);
    useEffect(() => {
        if (error.show) {
            console.log('Error = ', error);
        }
    }, [error]);
    return (
        <div className={styles.App}>
            <Switch>
                <Route path={pagePaths.MAIN_PAGE_PATH}>
                    <Intro>
                        <Header languages={localizations.getAvailableLanguages().map((language) => ({
                            key: language.toLowerCase(),
                            name: language === 'en' ? localizations.headerLanguageEn : localizations.headerLanguageUk
                        }))}
                                techWorks={techWorks}
                                activeLanguage={language}
                                setLanguage={setLanguage}
                                checkAuthorizedToken={checkAuthorizedToken}
                                isAuthorized={isAuthorized}
                                authorizedUser={authorizedUser}
                                login={login}
                                logout={logout}/>
                    </Intro>
                </Route>
                <Route path={pagePaths.SMARTID_PAGE_PATH}>
                    <Intro>
                        <Header languages={localizations.getAvailableLanguages().map((language) => ({
                            key: language,
                            name: language === 'en' ? localizations.headerLanguageEn : localizations.headerLanguageUk
                        }))}
                                techWorks={techWorks}
                                activeLanguage={language}
                                setLanguage={setLanguage}
                                checkAuthorizedToken={checkAuthorizedToken}
                                isAuthorized={isAuthorized}
                                authorizedUser={authorizedUser}
                                login={login}
                                logout={logout}/>
                    </Intro>
                </Route>
                <Route path={pagePaths.ROOT_PAGE_PATH}>
                    <Header languages={localizations.getAvailableLanguages().map((language) => ({
                        key: language,
                        name: language === 'en' ? localizations.headerLanguageEn : localizations.headerLanguageUk
                    }))}
                            techWorks={techWorks}
                            activeLanguage={language}
                            setLanguage={setLanguage}
                            checkAuthorizedToken={checkAuthorizedToken}
                            isAuthorized={isAuthorized}
                            authorizedUser={authorizedUser}
                            login={login}
                            logout={logout}/>
                </Route>
            </Switch>
            <Switch>
                <Route path={pagePaths.MAIN_PAGE_PATH}>
                    <Main checkAuthorizedToken={checkAuthorizedToken}
                          servicesRef={servicesRef}
                          activeLanguage={language}
                          authorizedToken={authorizedToken}
                          isAuthorized={isAuthorized}
                          login={login}/>
                </Route>
                <Route path={pagePaths.CERTIFICATES_PAGE_PATH}>
                    <Certificates personalCertificatesRef={personalCertificatesRef}
                                  isAuthorized={isAuthorized}
                                  authorizedToken={authorizedToken}
                                  activeLanguage={language}
                                  errorGetClientCertificates={errorGetClientCertificates}
                                  errorGetAcskCertificates={errorGetAcskCertificates}
                                  errorGetRevokedCertificates={errorGetRevokedCertificates}
                                  login={login}
                                  checkAuthorizedToken={checkAuthorizedToken}
                                  errorGetPersonalCertificates={errorGetPersonalCertificates}
                                  errorGetCertificates={errorGetCertificates}
                                  errorGetPdf={errorGetPdf}
                                  errorRevocationRequest={errorRevocationRequest}
                                  errorUnpublicationRequest={errorUnpublicationRequest}
                                  errorGenerationRequest={errorGenerationRequest}/>
                </Route>
                <Route path={pagePaths.NORMATIVE_BASE_PAGE_PATH}>
                    <NormativeBase/>
                </Route>
                <Route path={pagePaths.DOCUMENTS_PAGE_PATH}>
                    <Documents/>
                </Route>
                <Route path={pagePaths.SMARTID_PAGE_PATH}>
                    <SmartID/>
                </Route>
                <Route path={pagePaths.ONLINE_SERVICES_PAGE_PATH}>
                    <OnlineServices checkAuthorizedToken={checkAuthorizedToken}
                                    servicesRef={servicesRef}
                                    activeLanguage={language}
                                    authorizedToken={authorizedToken}
                                    isAuthorized={isAuthorized}
                                    login={login}/>
                </Route>
                <Route path={pagePaths.FREQUENTLY_ASKED_QUESTIONS_PAGE_PATH}>
                    <FrequentlyAskedQuestions/>
                </Route>
                <Route path={pagePaths.CONTACTS_PAGE_PATH}>
                    <Contacts/>
                </Route>
                <Route path={pagePaths.ROOT_PAGE_PATH}>
                    <Redirect from={pagePaths.ROOT_PAGE_PATH}
                              to={pagePaths.MAIN_PAGE_PATH}/>
                </Route>
            </Switch>
            <Footer/>
            {
                authorization.show && <Authorization widgetUrl={getWidgetUrl()}
                                                     widgetAuthorizationTokenUrl={backPaths.GET_AUTH_TOKEN_BACK_PATH}
                                                     successAuthorization={successAuthorization}
                                                     errorAuthorization={errorAuthorization}
                                                     reset={resetAuthorization}
                                                     callback={authorization.callback}
                                                     widgetLanguage={language}/>
            }
            {
                error.show && <Error errorCode={error.errorCode}
                                     reset={resetError}
                                     requestId={error.requestId}/>
            }
        </div>
    );
}

export default App;
