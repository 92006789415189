import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {default as BootstrapTooltip} from 'react-bootstrap/Tooltip';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

const overlayTriggerDelayShow = 150;
const overlayTriggerDelayHide = 150;
const tooltipId = 'tooltip';

function Tooltip(props) {
    const {messages, placement = 'top', children} = props;
    return (
        <div className={styles.Tooltip}>
            <BrowserView viewClassName={styles.Browser}>
                <OverlayTrigger placement={placement}
                                delayShow={overlayTriggerDelayShow}
                                delayHide={overlayTriggerDelayHide}
                                overlay={
                                    <BootstrapTooltip id={tooltipId}>
                                        {messages.map((message, index) => {
                                            return (
                                                <div key={index}>
                                                    {message}
                                                </div>
                                            );
                                        })}
                                    </BootstrapTooltip>
                                }>
                    {children}
                </OverlayTrigger>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <OverlayTrigger placement={placement}
                                delayShow={overlayTriggerDelayShow}
                                delayHide={overlayTriggerDelayHide}
                                overlay={
                                    <BootstrapTooltip id={tooltipId}>
                                        {messages.map((message, index) => {
                                            return (
                                                <div key={index}>
                                                    {message}
                                                </div>
                                            );
                                        })}
                                    </BootstrapTooltip>
                                }>
                    {children}
                </OverlayTrigger>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <OverlayTrigger placement={placement}
                                delayShow={overlayTriggerDelayShow}
                                delayHide={overlayTriggerDelayHide}
                                overlay={
                                    <BootstrapTooltip id={tooltipId}>
                                        {messages.map((message, index) => {
                                            return (
                                                <div key={index}>
                                                    {message}
                                                </div>
                                            );
                                        })}
                                    </BootstrapTooltip>
                                }>
                    {children}
                </OverlayTrigger>
            </MobileOnlyView>
        </div>
    );
}

Tooltip.propTypes = {
    messages: PropTypes.array.isRequired,
    placement: PropTypes.string,
};

export default Tooltip;
