import React from 'react';
import PropTypes from 'prop-types';
import styles from './Main.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as AcskLogo} from './acskLogo.svg';
import {ReactComponent as PbLogo} from './pbLogo.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Intro from '../Intro/Intro';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';
import Services from '../Services/Services';

function Main(props) {
    const {servicesRef, activeLanguage, authorizedToken, isAuthorized} = props;
    const {checkAuthorizedToken, login} = props;
    return (
        <div className={styles.Main}>
            <BrowserView viewClassName={styles.Browser}>
                <Intro>
                    <div className={styles.Info}>
                        <div className={styles.Logos}>
                            <AcskLogo className={styles.Logo}/>
                            <PbLogo className={styles.Logo}/>
                        </div>
                        <div className={styles.Title}>
                            <Headline contentMargin={'0'}
                                      contentFontWeight={'bold'}
                                      contentFontSize={'var(--display1-font-size)'}
                                      contentLineHeight={'var(--display1-line-height)'}>
                                {localizations.mainIntroTitle}
                            </Headline>
                        </div>
                        <div className={styles.Target}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader1-font-size)'}
                                 contentLineHeight={'var(--subheader1-line-height)'}>
                                {localizations.mainIntroTarget}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Description}>
                    <Row horizontal={true}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}>
                        {localizations.mainDescription}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Applications}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'0'}>
                            {localizations.mainApplications}
                        </Headline>
                    </div>
                    <div className={styles.Table}>
                        <Row list={true}
                             disabledIcon={true}
                             contentMargin={'0 0 30px'}>
                            {localizations.mainApplication1}
                        </Row>
                        <Row list={true}
                             disabledIcon={true}
                             contentMargin={'0 0 30px'}>
                            {localizations.mainApplication2}
                        </Row>
                        <Row list={true}
                             disabledIcon={true}
                             contentMargin={'0 0 30px'}>
                            {localizations.mainApplication3}
                        </Row>
                        <Row list={true}
                             disabledIcon={true}
                             dangerous={true}
                             contentMargin={'0 0 30px'}>
                            {localizations.mainApplication4}
                        </Row>
                        <Row list={true}
                             disabledIcon={true}
                             dangerous={true}>
                            {localizations.mainApplication5}
                        </Row>
                    </div>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.GeneralInformation}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'0'}>
                            {localizations.mainGeneralInformation}
                        </Headline>
                    </div>
                    <div className={styles.Table}>
                        <Row list={true}
                             disabledIcon={true}>
                            {localizations.mainGeneralInformation1}
                        </Row>
                    </div>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Rates}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'0'}>
                            {localizations.mainRates}
                        </Headline>
                    </div>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn1Name}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn2Name}
                                </Headline>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn1Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn1Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn2Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn2Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn3Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn3Content2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.OnlineServices}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'60px 0'}>
                            {localizations.mainOnlineServices}
                        </Headline>
                    </div>
                    <Services checkAuthorizedToken={checkAuthorizedToken}
                              ref={servicesRef}
                              activeLanguage={activeLanguage}
                              authorizedToken={authorizedToken}
                              isAuthorized={isAuthorized}
                              login={login}/>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <Intro>
                    <div className={styles.Info}>
                        <div className={styles.Logos}>
                            <AcskLogo className={styles.Logo}/>
                            <PbLogo className={styles.Logo}/>
                        </div>
                        <div className={styles.Title}>
                            <Headline contentMargin={'0'}
                                      contentFontWeight={'bold'}
                                      contentFontSize={'var(--display2-font-size)'}
                                      contentLineHeight={'var(--display2-line-height)'}>
                                {localizations.mainIntroTitle}
                            </Headline>
                        </div>
                        <div className={styles.Target}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader1-font-size)'}
                                 contentLineHeight={'var(--subheader1-line-height)'}>
                                {localizations.mainIntroTarget}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Description}>
                    <Row horizontal={true}
                         contentFontSize={'var(--subheader2-font-size)'}
                         contentLineHeight={'var(--subheader2-line-height)'}>
                        {localizations.mainDescription}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Applications}>
                    <Headline contentMargin={'0 0 45px'}>
                        {localizations.mainApplications}
                    </Headline>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication1}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication2}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication3}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         dangerous={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication4}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         dangerous={true}>
                        {localizations.mainApplication5}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.GeneralInformation}>
                    <Headline contentMargin={'0 0 45px'}>
                        {localizations.mainGeneralInformation}
                    </Headline>
                    <Row list={true}
                         disabledIcon={true}>
                        {localizations.mainGeneralInformation1}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Rates}>
                    <Headline contentMargin={'0 0 45px'}>
                        {localizations.mainRates}
                    </Headline>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn1Name}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn2Name}
                                </Headline>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn1Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn1Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn2Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn2Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn3Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body1-font-size)'}
                                     contentLineHeight={'var(--body1-line-height)'}>
                                    {localizations.mainRatesColumn3Content2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.OnlineServices}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'45px 0'}>
                            {localizations.mainOnlineServices}
                        </Headline>
                    </div>
                    <Services checkAuthorizedToken={checkAuthorizedToken}
                              ref={servicesRef}
                              activeLanguage={activeLanguage}
                              authorizedToken={authorizedToken}
                              isAuthorized={isAuthorized}
                              login={login}/>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <Intro>
                    <div className={styles.Info}>
                        <div className={styles.Logos}>
                            <AcskLogo className={styles.Logo}/>
                            <PbLogo className={styles.Logo}/>
                        </div>
                        <div className={styles.Title}>
                            <Headline contentMargin={'0'}
                                      contentFontWeight={'bold'}
                                      contentFontSize={'var(--headline1-font-size)'}
                                      contentLineHeight={'var(--headline1-line-height)'}>
                                {localizations.mainIntroTitle}
                            </Headline>
                        </div>
                        <div className={styles.Target}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader2-font-size)'}
                                 contentLineHeight={'var(--subheader2-line-height)'}>
                                {localizations.mainIntroTarget}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Description}>
                    <Row horizontal={true}>
                        {localizations.mainDescription}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Applications}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.mainApplications}
                    </Headline>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication1}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication2}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication3}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         dangerous={true}
                         contentMargin={'0 0 30px'}>
                        {localizations.mainApplication4}
                    </Row>
                    <Row list={true}
                         disabledIcon={true}
                         dangerous={true}>
                        {localizations.mainApplication5}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.GeneralInformation}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.mainGeneralInformation}
                    </Headline>
                    <Row list={true}
                         disabledIcon={true}>
                        {localizations.mainGeneralInformation1}
                    </Row>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Rates}>
                    <Headline contentMargin={'0 0 30px'}>
                        {localizations.mainRates}
                    </Headline>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--caption-bold-font-weight)'}
                                          contentFontSize={'var(--caption-bold-font-size)'}
                                          contentLineHeight={'var(--caption-bold-line-height)'}
                                          contentLetterSpacing={'var(--caption-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn1Name}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--caption-bold-font-weight)'}
                                          contentFontSize={'var(--caption-bold-font-size)'}
                                          contentLineHeight={'var(--caption-bold-line-height)'}
                                          contentLetterSpacing={'var(--caption-bold-letter-spacing)'}>
                                    {localizations.mainRatesColumn2Name}
                                </Headline>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn1Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn1Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn2Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn2Content2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn3Content1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.mainRatesColumn3Content2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Row}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.OnlineServices}>
                    <div className={styles.Title}>
                        <Headline>
                            {localizations.mainOnlineServices}
                        </Headline>
                    </div>
                    <Services checkAuthorizedToken={checkAuthorizedToken}
                              ref={servicesRef}
                              activeLanguage={activeLanguage}
                              authorizedToken={authorizedToken}
                              isAuthorized={isAuthorized}
                              login={login}/>
                </div>
            </MobileOnlyView>
        </div>
    );
}

Main.propTypes = {
    checkAuthorizedToken: PropTypes.func.isRequired,
    servicesRef: PropTypes.object.isRequired,
    activeLanguage: PropTypes.string.isRequired,
    authorizedToken: PropTypes.string,
    isAuthorized: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
}

export default Main;
