import React from 'react';
import PropTypes from 'prop-types';
import styles from './Headline.module.css';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

function Headline(props) {
    const {
        contentMargin,
        contentFontFamily,
        contentFontWeight,
        contentFontSize,
        contentLineHeight,
        contentLetterSpacing,
        contentColor
    } = props;
    const {children} = props;

    function getContentStyles() {
        const contentStyles = {};
        if (contentMargin) {
            contentStyles.margin = contentMargin;
        }
        if (contentFontFamily) {
            contentStyles.fontFamily = contentFontFamily;
        }
        if (contentFontWeight) {
            contentStyles.fontWeight = contentFontWeight;
        }
        if (contentFontSize) {
            contentStyles.fontSize = contentFontSize;
        }
        if (contentLineHeight) {
            contentStyles.lineHeight = contentLineHeight;
        }
        if (contentLetterSpacing) {
            contentStyles.letterSpacing = contentLetterSpacing;
        }
        if (contentColor) {
            contentStyles.color = contentColor;
        }
        return contentStyles;
    }

    return (
        <div className={styles.Headline}>
            <BrowserView style={getContentStyles()}
                         viewClassName={styles.Browser}>
                {children}
            </BrowserView>
            <TabletView style={getContentStyles()}
                        viewClassName={styles.Tablet}>
                {children}
            </TabletView>
            <MobileOnlyView style={getContentStyles()}
                            viewClassName={styles.Mobile}>
                {children}
            </MobileOnlyView>
        </div>
    );
}

Headline.propTypes = {
    contentMargin: PropTypes.string,
    contentFontFamily: PropTypes.string,
    contentFontWeight: PropTypes.string,
    contentFontSize: PropTypes.string,
    contentLineHeight: PropTypes.string,
    contentLetterSpacing: PropTypes.string,
    contentColor: PropTypes.string,
}

export default Headline;
