export const ROOT_PAGE_PATH = '/';
export const MAIN_PAGE_PATH = '/main';
export const CERTIFICATES_PAGE_PATH = '/certificates';
export const CERTIFICATES_CLIENTS_PAGE_PATH = '/certificates/clients';
export const CERTIFICATES_ACSK_PAGE_PATH = '/certificates/acsk';
export const CERTIFICATES_REVOKED_PAGE_PATH = '/certificates/revoked';
export const CERTIFICATES_PERSONAL_PAGE_PATH = '/certificates/personal';
export const NORMATIVE_BASE_PAGE_PATH = '/normative-base';
export const DOCUMENTS_PAGE_PATH = '/documents';
export const SMARTID_PAGE_PATH = '/smartid';
export const ONLINE_SERVICES_PAGE_PATH = '/online-services';
export const FREQUENTLY_ASKED_QUESTIONS_PAGE_PATH = '/frequently-asked-questions';
export const CONTACTS_PAGE_PATH = '/contacts';
