import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Row.module.css';
import {ReactComponent as ListIcon} from './listIcon.svg';
import {ReactComponent as DownloadIcon} from './downloadIcon.svg';
import {ReactComponent as LinkIcon} from './linkIcon.svg';
import {ReactComponent as ExpandIcon} from './expandIcon.svg';
import {ReactComponent as CollapseIcon} from './collapseIcon.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

function Row(props) {
    const {
        horizontal = false,
        topLine = false,
        rightLine = false,
        bottomLine = false,
        leftLine = false,
        list = false,
        download = false,
        disabledIcon = false,
        whiteIcon = false,
        dangerous = false,
        lineMaxWidth,
        lineColor,
        contentDisplay,
        contentMargin,
        contentFontSize,
        contentLineHeight,
        contentColor,
        link,
        collapse
    } = props;
    const {children} = props;
    const [isExpanded, setIsExpanded] = useState(false);

    function getLineStyles() {
        const lineStyles = {};
        if (lineMaxWidth) {
            lineStyles.maxWidth = lineMaxWidth;
        }
        if (lineColor) {
            if (horizontal) {
                lineStyles.color = lineColor;
            } else {
                lineStyles.background = lineColor;
            }
        }
        return lineStyles;
    }

    function getContentStyles() {
        const contentStyles = {};
        if (list) {
            contentStyles.justifyContent = 'normal';
        }
        if (contentDisplay) {
            contentStyles.display = contentDisplay;
        }
        if (children && !horizontal && (topLine || bottomLine)) {
            contentStyles.margin = '15px 0';
        }
        if (contentMargin) {
            contentStyles.margin = contentMargin;
        }
        if (contentFontSize) {
            contentStyles.fontSize = contentFontSize;
        }
        if (contentLineHeight) {
            contentStyles.lineHeight = contentLineHeight;
        }
        if (contentColor) {
            contentStyles.color = contentColor;
        }
        return contentStyles;
    }

    return (
        <div className={styles.Row}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    horizontal
                        ? <div className={styles.Horizontal}>
                            {
                                leftLine && <div style={getLineStyles()}
                                                 className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                            <div style={getContentStyles()}
                                 className={styles.Content}>
                                {
                                    link
                                        ? <a href={link}
                                             target={download ? undefined : '_blank'}
                                             rel={download ? undefined : 'noopener noreferrer'}
                                             className={styles.Link}>
                                            {children}
                                        </a>
                                        : children
                                }
                            </div>
                            {
                                rightLine && <div style={getLineStyles()}
                                                  className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                        </div>
                        : <div className={styles.Vertical}>
                            {
                                topLine && <hr style={getLineStyles()}
                                               className={styles.Line}/>
                            }
                            {
                                link
                                    ? <a href={link}
                                         target={download ? undefined : '_blank'}
                                         rel={download ? undefined : 'noopener noreferrer'}
                                         style={list ? {width: '100%'} : undefined}
                                         className={styles.Link}>
                                        <div style={getContentStyles()}
                                             className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            <div className={list ? undefined : styles.Text}>
                                                {children}
                                            </div>
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                                    </a>
                                    : collapse
                                        ? <div>
                                            <div style={getContentStyles()}
                                                 className={styles.ContentCollapsed}
                                                 onClick={() => {
                                                     setIsExpanded(!isExpanded)
                                                 }}>
                                                <div className={styles.HeadlineText}>
                                                    {collapse}
                                                </div>
                                                {
                                                    isExpanded && <CollapseIcon className={styles.CollapseIcon}/>
                                                }
                                                {
                                                    !isExpanded && <ExpandIcon className={styles.ExpandIcon}/>
                                                }
                                            </div>
                                            {
                                                isExpanded && <div style={getContentStyles()}
                                                                   className={styles.ContentExpanded}
                                                                   dangerouslySetInnerHTML={{__html: children}}/>
                                            }
                                        </div>
                                        : <div style={getContentStyles()}
                                               className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            {
                                                dangerous
                                                    ? <div className={list ? undefined : styles.Text}
                                                           dangerouslySetInnerHTML={{__html: children}}/>
                                                    : <div className={list ? undefined : styles.Text}>
                                                        {children}
                                                    </div>
                                            }
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                            }
                            {
                                bottomLine && <hr style={getLineStyles()}
                                                  className={styles.Line}/>
                            }
                        </div>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    horizontal
                        ? <div className={styles.Horizontal}>
                            {
                                leftLine && <div style={getLineStyles()}
                                                 className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                            <div style={getContentStyles()}
                                 className={styles.Content}>
                                {
                                    link
                                        ? <a href={link}
                                             target={download ? undefined : '_blank'}
                                             rel={download ? undefined : 'noopener noreferrer'}
                                             className={styles.Link}>
                                            {children}
                                        </a>
                                        : children
                                }
                            </div>
                            {
                                rightLine && <div style={getLineStyles()}
                                                  className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                        </div>
                        : <div className={styles.Vertical}>
                            {
                                topLine && <hr style={getLineStyles()}
                                               className={styles.Line}/>
                            }
                            {
                                link
                                    ? <a href={link}
                                         target={download ? undefined : '_blank'}
                                         rel={download ? undefined : 'noopener noreferrer'}
                                         style={list ? {width: '100%'} : undefined}
                                         className={styles.Link}>
                                        <div style={getContentStyles()}
                                             className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            <div className={list ? undefined : styles.Text}>
                                                {children}
                                            </div>
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                                    </a>
                                    : collapse
                                        ? <div>
                                            <div style={getContentStyles()}
                                                 className={styles.ContentCollapsed}
                                                 onClick={() => {
                                                     setIsExpanded(!isExpanded)
                                                 }}>
                                                <div className={styles.HeadlineText}>
                                                    {collapse}
                                                </div>
                                                {
                                                    isExpanded && <CollapseIcon className={styles.CollapseIcon}/>
                                                }
                                                {
                                                    !isExpanded && <ExpandIcon className={styles.ExpandIcon}/>
                                                }
                                            </div>
                                            {
                                                isExpanded && <div style={getContentStyles()}
                                                                   className={styles.ContentExpanded}
                                                                   dangerouslySetInnerHTML={{__html: children}}/>
                                            }
                                        </div>
                                        : <div style={getContentStyles()}
                                               className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            {
                                                dangerous
                                                    ? <div className={list ? undefined : styles.Text}
                                                           dangerouslySetInnerHTML={{__html: children}}/>
                                                    : <div className={list ? undefined : styles.Text}>
                                                        {children}
                                                    </div>
                                            }
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                            }
                            {
                                bottomLine && <hr style={getLineStyles()}
                                                  className={styles.Line}/>
                            }
                        </div>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    horizontal
                        ? <div className={styles.Horizontal}>
                            {
                                leftLine && <div style={getLineStyles()}
                                                 className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                            <div style={getContentStyles()}
                                 className={styles.Content}>
                                {
                                    link
                                        ? <a href={link}
                                             target={download ? undefined : '_blank'}
                                             rel={download ? undefined : 'noopener noreferrer'}
                                             className={styles.Link}>
                                            {children}
                                        </a>
                                        : children
                                }
                            </div>
                            {
                                rightLine && <div style={getLineStyles()}
                                                  className={styles.Line}>
                                    &nbsp;|&nbsp;
                                </div>
                            }
                        </div>
                        : <div className={styles.Vertical}>
                            {
                                topLine && <hr style={getLineStyles()}
                                               className={styles.Line}/>
                            }
                            {
                                link
                                    ? <a href={link}
                                         target={download ? undefined : '_blank'}
                                         rel={download ? undefined : 'noopener noreferrer'}
                                         style={list ? {width: '100%'} : undefined}
                                         className={styles.Link}>
                                        <div style={getContentStyles()}
                                             className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            <div className={list ? undefined : styles.Text}>
                                                {children}
                                            </div>
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                                    </a>
                                    : collapse
                                        ? <div>
                                            <div style={getContentStyles()}
                                                 className={styles.ContentCollapsed}
                                                 onClick={() => {
                                                     setIsExpanded(!isExpanded)
                                                 }}>
                                                <div className={styles.HeadlineText}>
                                                    {collapse}
                                                </div>
                                                {
                                                    isExpanded && <CollapseIcon className={styles.CollapseIcon}/>
                                                }
                                                {
                                                    !isExpanded && <ExpandIcon className={styles.ExpandIcon}/>
                                                }
                                            </div>
                                            {
                                                isExpanded && <div style={getContentStyles()}
                                                                   className={styles.ContentExpanded}
                                                                   dangerouslySetInnerHTML={{__html: children}}/>
                                            }
                                        </div>
                                        : <div style={getContentStyles()}
                                               className={styles.Content}>
                                            {
                                                list && <ListIcon className={styles.ListIcon}/>
                                            }
                                            {
                                                dangerous
                                                    ? <div className={list ? undefined : styles.Text}
                                                           dangerouslySetInnerHTML={{__html: children}}/>
                                                    : <div className={list ? undefined : styles.Text}>
                                                        {children}
                                                    </div>
                                            }
                                            {
                                                disabledIcon
                                                    ? undefined
                                                    : download
                                                        ? <DownloadIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                                        : <LinkIcon className={whiteIcon ? styles.WhiteIcon : styles.DarkIcon}/>
                                            }
                                        </div>
                            }
                            {
                                bottomLine && <hr style={getLineStyles()}
                                                  className={styles.Line}/>
                            }
                        </div>
                }
            </MobileOnlyView>
        </div>
    );
}

Row.propTypes = {
    horizontal: PropTypes.bool,
    topLine: PropTypes.bool,
    rightLine: PropTypes.bool,
    bottomLine: PropTypes.bool,
    leftLine: PropTypes.bool,
    list: PropTypes.bool,
    download: PropTypes.bool,
    disabledIcon: PropTypes.bool,
    whiteIcon: PropTypes.bool,
    dangerous: PropTypes.bool,
    lineMaxWidth: PropTypes.string,
    lineColor: PropTypes.string,
    contentDisplay: PropTypes.string,
    contentMargin: PropTypes.string,
    contentFontSize: PropTypes.string,
    contentLineHeight: PropTypes.string,
    contentColor: PropTypes.string,
    link: PropTypes.string,
    collapse: PropTypes.string,
}

export default Row;
