import React, {forwardRef, useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import styles from './CheckBox.module.css';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

const CheckBox = forwardRef((props, ref) => {
    const {id, title} = props;
    const {onChange} = props;
    const checkBoxRef = useRef();
    useImperativeHandle(ref, () => ({
        forceCheck() {
            if (!checkBoxRef.current.checked) {
                checkBoxRef.current.checked = true;
            }
        },
        forceUncheck() {
            if (checkBoxRef.current.checked) {
                checkBoxRef.current.checked = false;
            }
        }
    }));
    return (
        <div className={styles.CheckBox}>
            <BrowserView viewClassName={styles.Browser}>
                <input id={id}
                       ref={checkBoxRef}
                       className={styles.Input}
                       type={'checkbox'}
                       onChange={onChange}/>
                <label htmlFor={id}
                       title={title && title}/>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <input id={id}
                       ref={checkBoxRef}
                       className={styles.Input}
                       type={'checkbox'}
                       onChange={onChange}/>
                <label htmlFor={id}
                       title={title && title}/>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <input id={id}
                       ref={checkBoxRef}
                       className={styles.Input}
                       type={'checkbox'}
                       onChange={onChange}/>
                <label htmlFor={id}
                       title={title && title}/>
            </MobileOnlyView>
        </div>
    );
});

CheckBox.propTypes = {
    id: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default CheckBox;
