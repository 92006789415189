import React from 'react';
import styles from './Footer.module.css';
import localizations from '../../localizations/localizations';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Row from '../Row/Row';

function Footer() {
    return (
        <div className={styles.Footer}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.AcskInfo}>
                    {localizations.footerAcskInfo}
                </div>
                <div className={styles.AcskAddress}>
                    {localizations.footerAcskAddress}
                </div>
                <div className={styles.Phones}>
                    <Row horizontal={true}
                         lineColor={'var(--050-white)'}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}
                         contentColor={'var(--white)'}>
                        <a className={styles.Link}
                           href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                            {localizations.footerPhone1}
                        </a>
                    </Row>
                    <Row horizontal={true}
                         leftLine={true}
                         lineColor={'var(--050-white)'}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}
                         contentColor={'var(--white)'}>
                        <a className={styles.Link}
                           href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                            {localizations.footerPhone2}
                        </a>
                    </Row>
                </div>
                <div className={styles.PrivatBank}>
                    <span className={styles.PrivatBankInfo}>
                        {localizations.footerPrivatBank}
                    </span>
                    <div className={styles.Separator}>|</div>
                    <a className={styles.PrivatBankLink} href={localizations.footerPrivatBankPersonalDataLink}>
                        {localizations.footerPrivatBankPersonalData}
                    </a>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.AcskInfo}>
                    {localizations.footerAcskInfo}
                </div>
                <div className={styles.AcskAddress}>
                    {localizations.footerAcskAddress}
                </div>
                <div className={styles.Phones}>
                    <Row horizontal={true}
                         lineColor={'var(--050-white)'}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}
                         contentColor={'var(--white)'}>
                        <a className={styles.Link}
                           href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                            {localizations.footerPhone1}
                        </a>
                    </Row>
                    <Row horizontal={true}
                         leftLine={true}
                         lineColor={'var(--050-white)'}
                         contentFontSize={'var(--subheader1-font-size)'}
                         contentLineHeight={'var(--subheader1-line-height)'}
                         contentColor={'var(--white)'}>
                        <a className={styles.Link}
                           href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                            {localizations.footerPhone2}
                        </a>
                    </Row>
                </div>
                <div className={styles.PrivatBank}>
                    <span className={styles.PrivatBankInfo}>
                        {localizations.footerPrivatBank}
                    </span>
                    <div className={styles.Separator}>|</div>
                    <a className={styles.PrivatBankLink} href={localizations.footerPrivatBankPersonalDataLink}>
                        {localizations.footerPrivatBankPersonalData}
                    </a>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.AcskInfo}>
                    {localizations.footerAcskInfo}
                </div>
                <div className={styles.AcskAddress}>
                    {localizations.footerAcskAddress}
                </div>
                <div className={styles.Phones}>
                    <a className={styles.Link}
                       href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                        <Row topLine={true}
                             whiteIcon={true}
                             lineColor={'var(--050-white)'}
                             contentFontSize={'var(--subheader1-font-size)'}
                             contentLineHeight={'var(--subheader1-line-height)'}
                             contentColor={'var(--white)'}>
                            {localizations.footerPhone1}
                        </Row>
                    </a>
                    <a className={styles.Link}
                       href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                        <Row topLine={true}
                             bottomLine={true}
                             whiteIcon={true}
                             lineColor={'var(--050-white)'}
                             contentFontSize={'var(--subheader1-font-size)'}
                             contentLineHeight={'var(--subheader1-line-height)'}
                             contentColor={'var(--white)'}>
                            {localizations.footerPhone2}
                        </Row>
                    </a>
                </div>
                <div className={styles.PrivatBank}>
                    <span className={styles.PrivatBankInfo}>
                        {localizations.footerPrivatBank}
                    </span>
                    <div className={styles.Separator}>|</div>
                    <a className={styles.PrivatBankLink} href={localizations.footerPrivatBankPersonalDataLink}>
                        {localizations.footerPrivatBankPersonalData}
                    </a>
                </div>
            </MobileOnlyView>
        </div>
    );
}

export default Footer;
