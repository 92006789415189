import React from 'react';
import {CustomView, isMobileOnly} from 'react-device-detect';

function MobileOnlyView({children, ...props}) {
    return (
        <CustomView condition={isMobileOnly} {...props}>
            {children}
        </CustomView>
    );
}

export default MobileOnlyView;
