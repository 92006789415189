import React, {useRef, useCallback, useEffect} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './Authorization.module.css';
import {useOutsideClickDetector} from '../../hooks/useOutsideClickDetector';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

const widgetContainerId = 'p24LoginWidgetContainer';
const widgetId = 'p24LoginWidget';

function Authorization(props) {
    const {widgetUrl, widgetAuthorizationTokenUrl, widgetLanguage = 'uk'} = props;
    const {successAuthorization, errorAuthorization, reset, callback} = props;
    const frameRef = useRef(null);
    useOutsideClickDetector(frameRef, () => reset());

    const initWidget = useCallback((sid) => {
        const lang = widgetLanguage === 'uk' ? 'ua' : widgetLanguage;
        window.p24LoginWidgetCallback = widget => {
            widget.init({
                container: document.getElementById(widgetContainerId),
                lang: lang,
                urlParams: {
                    sid: sid,
                },
                onSuccess: function (data) {
                    successAuthorization(data.dyn_string, callback);
                    reset();
                },
            });
        };
    }, [widgetLanguage, successAuthorization, reset, callback]);

    const mountAuthorization = useCallback(() => {
        const script = document.createElement('script');
        script.id = widgetId;
        script.src = widgetUrl;
        script.async = true;
        script.type = 'text/javascript';
        document.head.appendChild(script);
    }, [widgetUrl]);

    function unmountAuthorization() {
        const p24LoginWidget = document.getElementById(widgetId);
        if (p24LoginWidget !== null) {
            p24LoginWidget.parentNode.removeChild(p24LoginWidget);
        }
    }

    useEffect(() => {
        axios.get(widgetAuthorizationTokenUrl)
            .then(response => {
                initWidget(response.data.sid);
                mountAuthorization();
            })
            .catch(error => {
                console.log('Get authorization token response = ', error.response);
                errorAuthorization(100, error.response.headers.requestid);
                reset();
            });
        return () => unmountAuthorization();
        // eslint-disable-next-line
    }, []);
    return (
        <div className={styles.Authorization}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div id={widgetContainerId}/>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div id={widgetContainerId}/>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.Blur}/>
                <div ref={frameRef}
                     className={styles.Frame}>
                    <div className={styles.Container}>
                        <div id={widgetContainerId}/>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

Authorization.propTypes = {
    widgetUrl: PropTypes.string.isRequired,
    widgetAuthorizationTokenUrl: PropTypes.string.isRequired,
    successAuthorization: PropTypes.func.isRequired,
    errorAuthorization: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    callback: PropTypes.func,
    widgetLanguage: PropTypes.string,
}

export default Authorization;
