import React from 'react';
import styles from './Intro.module.css';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

function Intro(props) {
    const {children} = props;
    return (
        <div className={styles.Intro}>
            <BrowserView viewClassName={styles.Browser}>
                {children}
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {children}
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {children}
            </MobileOnlyView>
        </div>
    );
}

export default Intro;
