import React, {forwardRef, useState, useRef, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './Recaptcha.module.css';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';

const Recaptcha = forwardRef((props, ref) => {
    const {siteKey, language} = props;
    const {onChange} = props;
    const [ReCaptcha, setReCaptcha] = useState(null);
    const reCaptchaRef = useRef();
    useImperativeHandle(ref, () => ({
        forceReset() {
            resetReCaptcha();
        }
    }));
    useEffect(() => {
        initReCaptcha()
            .then(() => {
                console.log('Recaptcha updated');
            });
    }, [language]);
    useEffect(() => {
        initReCaptcha()
            .then(() => {
                console.log('Recaptcha loaded');
            });
        return () => {
            resetReCaptcha();
        };
    }, []);

    async function initReCaptcha() {
        setReCaptcha(null);
        const {default: ReCAPTCHA} = await import('react-google-recaptcha');
        setReCaptcha(ReCAPTCHA);
    }

    function resetReCaptcha() {
        reCaptchaRef.current.reset();
    }

    return (
        <div className={styles.Recaptcha}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    ReCaptcha && <ReCaptcha ref={reCaptchaRef}
                                            sitekey={siteKey}
                                            hl={language}
                                            onChange={onChange}/>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    ReCaptcha && <ReCaptcha ref={reCaptchaRef}
                                            sitekey={siteKey}
                                            hl={language}
                                            onChange={onChange}/>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    ReCaptcha && <ReCaptcha ref={reCaptchaRef}
                                            sitekey={siteKey}
                                            hl={language}
                                            onChange={onChange}/>
                }
            </MobileOnlyView>
        </div>
    );
});

Recaptcha.propTypes = {
    siteKey: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Recaptcha;
