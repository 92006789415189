import React, {useState} from 'react';
import styles from './Documents.module.css';
import localizations from '../../localizations/localizations';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import conformityCertificate from './conformityCertificate.jpg';
import hsmConclution from './hsmConclution.jpg'
import gryadaConclutionCropped from './gryadaConclutionCropped.jpg'
import gryadaFullConclution from './gryadaFullConclution.jpg'
import expertOpinion from './expertOpinion.jpg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

const images = [
    conformityCertificate,
    expertOpinion,
    hsmConclution,
    gryadaFullConclution
];

function Documents() {
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    function openLightbox(imageIndex) {
        setIsOpen(true);
        setImageIndex(imageIndex);
    }

    return (
        <div className={styles.Documents}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Wrapper}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'60px 0'}
                                  contentFontWeight={'bold'}
                                  contentFontSize={'var(--display2-font-size)'}
                                  contentLineHeight={'var(--display2-line-height)'}>
                            {localizations.documentsTitle}
                        </Headline>
                    </div>
                    <div className={styles.Table}>
                        <div className={styles.Images}>
                            <img className={styles.Image}
                                 src={conformityCertificate}
                                 alt={'Logo'}
                                 onClick={() => openLightbox(0)}/>
                            <img className={styles.Image}
                                 src={expertOpinion}
                                 alt={'Logo'}
                                 onClick={() => openLightbox(1)}/>
                            <img className={styles.Image}
                                 src={hsmConclution}
                                 alt={'Logo'}
                                 onClick={() => openLightbox(2)}/>
                            <img className={styles.Image}
                                 src={gryadaConclutionCropped}
                                 alt={'Logo'}
                                 onClick={() => openLightbox(3)}/>
                        </div>
                        {
                            isOpen && <Lightbox mainSrc={images[imageIndex]}
                                                nextSrc={images[(imageIndex + 1) % images.length]}
                                                prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                                                imageTitle={localizations[`documentsImage${imageIndex + 1}`]}
                                                onCloseRequest={() => setIsOpen(false)}
                                                onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                                                onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}/>
                        }
                        <div className={styles.Links}>
                            <Row topLine={true}
                                 link={'https://zc.bank.gov.ua/na-certificates?ItemId=15'}>
                                {localizations.documentsLink1}
                            </Row>
                            <Row topLine={true}
                                 link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB.pdf'}>
                                {localizations.documentsLink2}
                            </Row>
                            <Row topLine={true}
                                 link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB_changes_1.pdf'}>
                                {localizations.documentsLink9}
                            </Row>
                            <Row topLine={true}
                                 link={'https://acsk.privatbank.ua/arch/docs/agreement_for_individual.pdf'}>
                                {localizations.documentsLink6}
                            </Row>
                            <Row topLine={true}
                                 link={'https://acsk.privatbank.ua/arch/docs/agreement_for_legal.pdf'}>
                                {localizations.documentsLink7}
                            </Row>
                            <Row topLine={true}
                                 bottomLine={true}
                                 link={'https://acsk.privatbank.ua/arch/docs/Documentation.pdf'}>
                                {localizations.documentsLink8}
                            </Row>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.documentsTitle}
                    </Headline>
                </div>
                <div className={styles.Images}>
                    <img className={styles.Image}
                         src={conformityCertificate}
                         alt={'Logo'}
                         onClick={() => openLightbox(0)}/>
                    <img className={styles.Image}
                         src={expertOpinion}
                         alt={'Logo'}
                         onClick={() => openLightbox(1)}/>
                    <img className={styles.Image}
                         src={hsmConclution}
                         alt={'Logo'}
                         onClick={() => openLightbox(2)}/>
                    <img className={styles.Image}
                         src={gryadaConclutionCropped}
                         alt={'Logo'}
                         onClick={() => openLightbox(3)}/>
                </div>
                {
                    isOpen && <Lightbox mainSrc={images[imageIndex]}
                                        nextSrc={images[(imageIndex + 1) % images.length]}
                                        prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                                        imageTitle={localizations[`documentsImage${imageIndex + 1}`]}
                                        onCloseRequest={() => setIsOpen(false)}
                                        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                                        onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}/>
                }
                <div className={styles.Links}>
                    <Row topLine={true}
                         link={'https://zc.bank.gov.ua/na-certificates?ItemId=15'}>
                        {localizations.documentsLink1}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB.pdf'}>
                        {localizations.documentsLink2}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB_changes_1.pdf'}>
                        {localizations.documentsLink9}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/agreement_for_individual.pdf'}>
                        {localizations.documentsLink6}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/agreement_for_legal.pdf'}>
                        {localizations.documentsLink7}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Documentation.pdf'}>
                        {localizations.documentsLink8}
                    </Row>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.documentsTitle}
                    </Headline>
                </div>
                <div className={styles.Images}>
                    <img className={styles.Image}
                         src={conformityCertificate}
                         alt={'Logo'}
                         onClick={() => openLightbox(0)}/>
                    <img className={styles.Image}
                         src={expertOpinion}
                         alt={'Logo'}
                         onClick={() => openLightbox(1)}/>
                    <img className={styles.Image}
                         src={hsmConclution}
                         alt={'Logo'}
                         onClick={() => openLightbox(2)}/>
                    <img className={styles.Image}
                         src={gryadaConclutionCropped}
                         alt={'Logo'}
                         onClick={() => openLightbox(3)}/>
                </div>
                {
                    isOpen && <Lightbox mainSrc={images[imageIndex]}
                                        nextSrc={images[(imageIndex + 1) % images.length]}
                                        prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                                        imageTitle={localizations[`documentsImage${imageIndex + 1}`]}
                                        onCloseRequest={() => setIsOpen(false)}
                                        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
                                        onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}/>
                }
                <div className={styles.Links}>
                    <Row topLine={true}
                         link={'https://zc.bank.gov.ua/na-certificates?ItemId=15'}>
                        {localizations.documentsLink1}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB.pdf'}>
                        {localizations.documentsLink2}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Reglament_ACSK_PB_changes_1.pdf'}>
                        {localizations.documentsLink9}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/agreement_for_individual.pdf'}>
                        {localizations.documentsLink6}
                    </Row>
                    <Row topLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/agreement_for_legal.pdf'}>
                        {localizations.documentsLink7}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Documentation.pdf'}>
                        {localizations.documentsLink8}
                    </Row>
                </div>
            </MobileOnlyView>
        </div>
    );
}

export default Documents;
