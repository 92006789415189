import React from 'react';
import {CustomView, isTablet, isMobileOnly} from 'react-device-detect';

function TabletView({children, ...props}) {
    return (
        <CustomView condition={isTablet && !isMobileOnly} {...props}>
            {children}
        </CustomView>
    );
}

export default TabletView;
