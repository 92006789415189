import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import axios from 'axios';
import {isIOS, isSafari} from 'react-device-detect';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import PropTypes from 'prop-types';
import styles from './PersonalCertificates.module.css';
import localizations from '../../localizations/localizations';
import {useLocation} from 'react-router-dom';
import queryParser from 'query-string';
import * as backPaths from '../../paths/backPaths';
import * as servicePaths from '../../paths/servicePaths';
import {useOutsideClickDetector} from '../../hooks/useOutsideClickDetector';
import {ReactComponent as JksSignIcon} from './jksSignIcon.svg';
import {ReactComponent as JksStampIcon} from './jksStampIcon.svg';
import {ReactComponent as SmartIdSignIcon} from './smartIdSignIcon.svg';
import {ReactComponent as SmartIdInactiveSignIcon} from './smartidSignInactiveIcon.svg';
import {ReactComponent as SmartIdInactiveStampIcon} from './smartIdStampInactiveIcon.svg';
import {ReactComponent as SmartIdStampIcon} from './smartIdStampIcon.svg';
import {ReactComponent as PublicCertificateIcon} from './publicCertificateIcon.svg';
import {ReactComponent as UnpublicCertificateIcon} from './unpublicCertificateIcon.svg';
import {ReactComponent as DownloadCertificatesIcon} from './downloadCertificatesIcon.svg';
import {ReactComponent as DownloadPdfsIcon} from './downloadPdfsIcon.svg';
import {ReactComponent as RevokeCertificatesIcon} from './revokeCertificatesIcon.svg';
import {ReactComponent as UnpublicateCertificatesIcon} from './unpublicateCertificatesIcon.svg';
import {ReactComponent as GenerateCertificatesIcon} from './generateCertificatesIcon.svg';
import {ReactComponent as UnauthorizedUserIcon} from './unauthorizedUserIcon.svg';
import {ReactComponent as LoaderAnimation} from './loaderAnimation.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import CheckBox from '../CheckBox/CheckBox';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';
import Tooltip from '../Tooltip/Tooltip';

const searchMinLength = 3;
const searchDebounce = 1000;
const statusFilters = ['all', 'active', 'revoked', 'blocked'];
const typeFilters = ['all', 'sign', 'stamp'];
const keyFilters = ['all', 'jks', 'smartId'];
const userTypeFilters = ['all', 'individual', 'legal', 'employee'];
const orgNameFilters = ['all'];
const sorts = ['default', 'startDate', 'revokedDate', 'status', 'type', 'key', 'owner', 'orgName', 'userType'];
const notFoundIndex = 'not-found';
const certificateIndexPattern = 'certificate-{index}';
const processingIndex = 'processing';
const maxSelectCertificates = 40;
const selectedCertificateBackgroundColor = 'var(--004-black)';
const personalCertificatesLoadingIndex = 'personal-certificates-loading';
const personalCertificatesEmptyIndex = 'personal-certificates-empty';
const personalCertificatesSearchIndex = 'personal-certificates-search';
const shakeColor = 'var(--black)';
const checkedDefaultAnimationDuration = '300ms';
const checkedDefaultColor = '';
const checkedDefaultEmptyDuration = '0ms';
const shakeDuration = 1000;

let compromisedId = undefined;
let lastSearchValue = undefined;
let personalData = undefined;
let personalCertificates = [];
let searchedPersonalCertificates = [];
let statusFilteredPersonalCertificates = [];
let typeFilteredPersonalCertificates = [];
let keyFilteredPersonalCertificates = [];
let userTypeFilteredPersonalCertificates = [];
let orgNameFilteredPersonalCertificates = [];
let statusFilterType = statusFilters[0];
let typeFilterType = typeFilters[0];
let keyFilterType = keyFilters[0];
let userTypeFilterType = userTypeFilters[0];
let orgNameFilterType = orgNameFilters[0];
let sortType = sorts[0];
let certificatesChecked = [];
let hideProcessing = true;

let stopLoading = undefined;

const PersonalCertificates = forwardRef((props, ref) => {
    const {isAuthorized, authorizedToken, activeLanguage} = props;
    const {
        login,
        checkAuthorizedToken,
        errorGetPersonalCertificates,
        errorGetCertificates,
        errorGetPdf,
        errorRevocationRequest,
        errorUnpublicationRequest,
        errorGenerationRequest
    } = props;
    const location = useLocation();
    const [sortedPersonalCertificates, setSortedPersonalCertificates] = useState([]);
    const [reloadCounter, setReloadCounter] = useState(0);
    const multiplyCheckBoxRef = useRef();
    const singleCheckBoxRefs = useRef([]);
    const checkedRef = useRef();
    const blurRef = useRef();
    const frameRef = useRef();
    useOutsideClickDetector(frameRef, () => closeModals());
    let searchTimer = null;
    let shakeTimer = null;
    useImperativeHandle(ref, () => ({
        reload() {
            clearTimeout(shakeTimer);
            clearTimeout(searchTimer);
            stopLoading = undefined;
            hideProcessing = true;
            certificatesChecked = [];
            sortType = sorts[0];
            typeFilterType = typeFilters[0];
            statusFilterType = statusFilters[0];
            orgNameFilteredPersonalCertificates = [];
            userTypeFilteredPersonalCertificates = [];
            keyFilteredPersonalCertificates = [];
            typeFilteredPersonalCertificates = [];
            statusFilteredPersonalCertificates = [];
            searchedPersonalCertificates = [];
            personalCertificates = [];
            personalData = undefined;
            lastSearchValue = undefined;
            compromisedId = undefined;
            orgNameFilters.splice(1, orgNameFilters.length);
            if (document.getElementById(personalCertificatesLoadingIndex)) {
                document.getElementById(personalCertificatesLoadingIndex).style.display = '';
            }
            if (document.getElementById(personalCertificatesEmptyIndex)) {
                document.getElementById(personalCertificatesEmptyIndex).style.display = 'none';
            }
            if (document.getElementById(personalCertificatesSearchIndex)) {
                document.getElementById(personalCertificatesSearchIndex).style.display = 'none';
            }
            checkAuthorizedToken(() => {
                closeModals();
                setReloadCounter(reloadCounter + 1);
            });
        }
    }));

    useEffect(() => {
        if (reloadCounter) {
            console.log(`Reload = ${reloadCounter}`);
        }
    }, [reloadCounter]);

    useEffect(() => {
        if (isAuthorized) {
            defineCompromisedId();
            const request = {
                token: authorizedToken,
            };
            if (compromisedId) {
                request.compromised = compromisedId;
            }
            checkAuthorizedToken(() => getPersonalCertificates(request));
        }
        // eslint-disable-next-line
    }, [isAuthorized, reloadCounter]);
    useEffect(() => {
        console.log('Sorted personal certificates = ', sortedPersonalCertificates);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = false;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = true;
            document.getElementById(processingIndex).hidden = true;
        }
    }, [sortedPersonalCertificates]);
    useEffect(() => {
        if (sortedPersonalCertificates) {
            if (certificatesChecked.length >= sortedPersonalCertificates.length) {
                let shouldCheck = true;
                sortedPersonalCertificates.some(sortedPersonalCertificate => {
                    if (!certificatesChecked.includes(sortedPersonalCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            certificatesChecked.forEach(certificateChecked => {
                singleCheckBoxRefs.current[certificateChecked] && singleCheckBoxRefs.current[certificateChecked].forceCheck();
                if (document.getElementById(certificateIndexPattern.replace('{index}', certificateChecked))) {
                    document.getElementById(certificateIndexPattern.replace('{index}', certificateChecked)).style.background = selectedCertificateBackgroundColor;
                }
            });
        }
    }, [sortedPersonalCertificates]);
    useEffect(() => {
        return () => {
            clearTimeout(shakeTimer);
            clearTimeout(searchTimer);
            stopLoading = undefined;
            hideProcessing = true;
            certificatesChecked = [];
            sortType = sorts[0];
            typeFilterType = typeFilters[0];
            statusFilterType = statusFilters[0];
            orgNameFilteredPersonalCertificates = [];
            userTypeFilteredPersonalCertificates = [];
            keyFilteredPersonalCertificates = [];
            typeFilteredPersonalCertificates = [];
            statusFilteredPersonalCertificates = [];
            searchedPersonalCertificates = [];
            personalCertificates = [];
            personalData = undefined;
            lastSearchValue = undefined;
            compromisedId = undefined;
            orgNameFilters.splice(1, orgNameFilters.length);
        };
    }, [shakeTimer, searchTimer]);

    function defineCompromisedId() {
        const {compromised} = queryParser.parse(location.search);
        if (compromised) {
            compromisedId = compromised;
        } else {
            compromisedId = undefined;
        }
        console.log('Compromised ID = ', compromisedId);
    }

    function getPersonalCertificates(request) {
        axios.post(backPaths.GET_PERSONAL_CERTIFICATES_BACK_PATH, request)
            .then(response => {
                console.log('Get personal certificates response = ', response);
                stopLoading = () => {
                    if (document.getElementById(personalCertificatesLoadingIndex)) {
                        document.getElementById(personalCertificatesLoadingIndex).style.display = 'none';
                    }
                    if (document.getElementById(personalCertificatesEmptyIndex)) {
                        document.getElementById(personalCertificatesEmptyIndex).style.display = '';
                    }
                    if (document.getElementById(personalCertificatesSearchIndex)) {
                        document.getElementById(personalCertificatesSearchIndex).style.display = '';
                    }
                }
                onStart(response.data);
                if (response.data.length > 0) {
                    if (document.getElementById(notFoundIndex)) {
                        document.getElementById(notFoundIndex).hidden = true;
                    }
                    if (document.getElementById(processingIndex)) {
                        hideProcessing = false;
                        document.getElementById(processingIndex).hidden = false;
                    }
                }
            })
            .catch(error => {
                console.log('Get personal certificates response = ', error.response);
                stopLoading = () => {
                    if (document.getElementById(personalCertificatesLoadingIndex)) {
                        document.getElementById(personalCertificatesLoadingIndex).style.display = 'none';
                    }
                    if (document.getElementById(personalCertificatesEmptyIndex)) {
                        document.getElementById(personalCertificatesEmptyIndex).style.display = '';
                    }
                    if (document.getElementById(personalCertificatesSearchIndex)) {
                        document.getElementById(personalCertificatesSearchIndex).style.display = '';
                    }
                }
                stopLoading();
                stopLoading = undefined;
                errorGetPersonalCertificates(600, error.response.headers.requestid);
            });
    }

    function onStart(data) {
        personalData = data;
        console.log('Personal data = ', personalData);
        if (personalData.organizations.length > 0) {
            personalData.organizations.forEach(organization => {
                orgNameFilters.push(organization.orgName);
            });
        }
        personalCertificates = data.certificates;
        console.log('Personal certificates = ', personalCertificates);
        doSearch(personalCertificates);
    }

    function onSearch(event) {
        const {value} = event.target;
        clearTimeout(searchTimer);
        event.persist();
        searchTimer = setTimeout(() => {
            console.log('Search value - ', value);
            if (value.length >= searchMinLength) {
                if (document.getElementById(notFoundIndex)) {
                    document.getElementById(notFoundIndex).hidden = true;
                }
                if (document.getElementById(processingIndex)) {
                    hideProcessing = false;
                    document.getElementById(processingIndex).hidden = false;
                }
                if (lastSearchValue) {
                    if (lastSearchValue.length >= value.length) {
                        doSearch(search(personalCertificates, value));
                    } else {
                        doSearch(search(searchedPersonalCertificates, value));
                    }
                } else {
                    doSearch(search(searchedPersonalCertificates, value));
                }
                lastSearchValue = value;
            } else {
                if (searchedPersonalCertificates.length !== personalCertificates.length) {
                    if (document.getElementById(notFoundIndex)) {
                        document.getElementById(notFoundIndex).hidden = true;
                    }
                    if (document.getElementById(processingIndex)) {
                        hideProcessing = false;
                        document.getElementById(processingIndex).hidden = false;
                    }
                    doSearch(personalCertificates);
                    lastSearchValue = undefined;
                }
            }
        }, searchDebounce);
    }

    function doSearch(certificates) {
        searchedPersonalCertificates = certificates;
        console.log('Searched personal certificates = ', searchedPersonalCertificates);
        doStatusFilter();
    }

    function search(certificates, searchValue) {
        searchValue = searchValue.toLowerCase();
        return certificates.filter(certificate => {
            const fields = Object.keys(certificate);
            return fields.some(field => {
                if (typeof certificate[field] === 'string') {
                    return certificate[field].toLowerCase().includes(searchValue);
                } else {
                    return false;
                }
            });
        });
    }

    function onStatusFilter(type) {
        statusFilterType = type;
        console.log('Status filter type = ', statusFilterType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        doStatusFilter();
    }

    function doStatusFilter() {
        statusFilteredPersonalCertificates = statusFilter(searchedPersonalCertificates);
        console.log('Status filtered personal certificates = ', statusFilteredPersonalCertificates);
        doTypeFilter();
    }

    function statusFilter(certificates) {
        return certificates.filter(certificate => {
            if (statusFilterType === 'active') {
                return certificate.status === 1;
            } else if (statusFilterType === 'revoked') {
                return certificate.status === 2;
            } else if (statusFilterType === 'blocked') {
                return certificate.status === 3;
            } else {
                return true;
            }
        });
    }

    function onTypeFilter(type) {
        typeFilterType = type;
        console.log('Type filter type = ', typeFilterType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        doTypeFilter();
    }

    function doTypeFilter() {
        typeFilteredPersonalCertificates = typeFilter(statusFilteredPersonalCertificates);
        console.log('Type filtered personal certificates = ', typeFilteredPersonalCertificates);
        doKeyFilter();
    }

    function typeFilter(certificates) {
        return certificates.filter(certificate => {
            if (typeFilterType === 'sign') {
                return certificate.isSign === true;
            } else if (typeFilterType === 'stamp') {
                return certificate.isSign === false;
            } else {
                return true;
            }
        });
    }

    function onKeyFilter(type) {
        keyFilterType = type;
        console.log('Key filter type = ', keyFilterType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        doKeyFilter();
    }

    function doKeyFilter() {
        keyFilteredPersonalCertificates = keyFilter(typeFilteredPersonalCertificates);
        console.log('Key filtered personal certificates = ', keyFilteredPersonalCertificates);
        doUserTypeFilter();
    }

    function keyFilter(certificates) {
        return certificates.filter(certificate => {
            if (keyFilterType === 'jks') {
                return certificate.type === 1;
            } else if (keyFilterType === 'smartId') {
                return certificate.type === 2;
            } else {
                return true;
            }
        });
    }

    function onUserTypeFilter(type) {
        userTypeFilterType = type;
        console.log('User type filter type = ', userTypeFilterType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        doUserTypeFilter();
    }

    function doUserTypeFilter() {
        userTypeFilteredPersonalCertificates = userTypeFilter(keyFilteredPersonalCertificates);
        console.log('User type filtered personal certificates = ', userTypeFilteredPersonalCertificates);
        if (personalData.organizations.length > 0) {
            doOrgNameFilter();
        } else {
            setTimeout(() => {
                doSort();
            }, 25);
        }
    }

    function userTypeFilter(certificates) {
        return certificates.filter(certificate => {
            if (userTypeFilterType === 'individual') {
                return certificate.userType === 2;
            } else if (userTypeFilterType === 'legal') {
                return certificate.userType === 1;
            } else if (userTypeFilterType === 'employee') {
                return certificate.userType === 0;
            } else {
                return true;
            }
        });
    }

    function onOrgNameFilter(type) {
        orgNameFilterType = type;
        console.log('Org name filter type = ', orgNameFilterType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        doOrgNameFilter();
    }

    function doOrgNameFilter() {
        orgNameFilteredPersonalCertificates = orgNameFilter(userTypeFilteredPersonalCertificates);
        console.log('Org name filtered personal certificates = ', orgNameFilteredPersonalCertificates);
        setTimeout(() => {
            doSort();
        }, 25);
    }

    function orgNameFilter(certificates) {
        return certificates.filter(certificate => {
            if (orgNameFilterType === 'all') {
                return true;
            } else {
                return orgNameFilterType === certificate.orgName;
            }
        });
    }

    function onSort(type) {
        sortType = type;
        console.log('Sort type = ', sortType);
        if (document.getElementById(notFoundIndex)) {
            document.getElementById(notFoundIndex).hidden = true;
        }
        if (document.getElementById(processingIndex)) {
            hideProcessing = false;
            document.getElementById(processingIndex).hidden = false;
        }
        setTimeout(() => {
            doSort();
        }, 25);
    }

    function doSort() {
        setSortedPersonalCertificates(sort(personalData.organizations.length > 0
            ? [...orgNameFilteredPersonalCertificates]
            : [...userTypeFilteredPersonalCertificates]));
        if (stopLoading) {
            stopLoading();
            stopLoading = undefined;
        }
    }

    function sort(certificates) {
        return certificates.sort((firstCertificate, secondCertificate) => {
            if (sortType === 'startDate') {
                const firstDateParts = firstCertificate.startDate.split('.');
                const firstDate = new Date(
                    Number.parseInt(firstDateParts[2]),
                    Number.parseInt(firstDateParts[1]),
                    Number.parseInt(firstDateParts[0]),
                    0,
                    0,
                    0,
                    0);
                const secondDateParts = secondCertificate.startDate.split('.');
                const secondDate = new Date(
                    Number.parseInt(secondDateParts[2]),
                    Number.parseInt(secondDateParts[1]),
                    Number.parseInt(secondDateParts[0]),
                    0,
                    0,
                    0,
                    0);
                if (firstCertificate.isRevoked === secondCertificate.isRevoked) {
                    return secondDate - firstDate;
                } else {
                    return firstCertificate.isRevoked
                        ? 1
                        : -1;
                }
            } else if (sortType === 'revokedDate') {
                const firstDateString = firstCertificate.revokedDate;
                const secondDateString = secondCertificate.revokedDate;
                if (firstDateString === secondDateString) {
                    return 0;
                }
                if (!firstDateString) {
                    return 1;
                }
                if (!secondDateString) {
                    return -1;
                }
                const firstDateParts = firstDateString.split('.');
                const firstDate = new Date(
                    Number.parseInt(firstDateParts[2]),
                    Number.parseInt(firstDateParts[1]),
                    Number.parseInt(firstDateParts[0]),
                    0,
                    0,
                    0,
                    0);
                const secondDateParts = secondDateString.split('.');
                const secondDate = new Date(
                    Number.parseInt(secondDateParts[2]),
                    Number.parseInt(secondDateParts[1]),
                    Number.parseInt(secondDateParts[0]),
                    0,
                    0,
                    0,
                    0);
                return secondDate - firstDate;
            } else if (sortType === 'status') {
                return firstCertificate.status - secondCertificate.status;
            } else if (sortType === 'type') {
                return firstCertificate.isSign === secondCertificate.isSign
                    ? 0
                    : firstCertificate.isSign
                        ? -1
                        : 1;
            } else if (sortType === 'key') {
                return secondCertificate.type - firstCertificate.type;
            } else if (sortType === 'owner') {
                return firstCertificate.owner.localeCompare(secondCertificate.owner);
            } else if (sortType === 'orgName') {
                return firstCertificate.orgName.localeCompare(secondCertificate.orgName);
            } else if (sortType === 'userType') {
                return secondCertificate.userType - firstCertificate.userType;
            } else {
                return firstCertificate.index - secondCertificate.index;
            }
        });
    }

    function onChangeMultiCheckBox(event) {
        if (certificatesChecked.length < maxSelectCertificates || !event.target.checked) {
            let oldCertificatesCheckedLength = certificatesChecked.length;
            if (event.target.checked) {
                if (sortedPersonalCertificates.length === personalCertificates.length) {
                    certificatesChecked.splice(0, certificatesChecked.length);
                    for (let i = 0; i < (maxSelectCertificates >= personalCertificates.length ? personalCertificates.length : maxSelectCertificates); i++) {
                        certificatesChecked.push(sortedPersonalCertificates[i].index);
                    }
                    oldCertificatesCheckedLength = 0;
                    singleCheckBoxRefs.current.forEach(singleCheckBoxRef => singleCheckBoxRef && singleCheckBoxRef.forceUncheck());
                    sortedPersonalCertificates.forEach(sortedPersonalCertificate => {
                        if (document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString()))) {
                            document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString())).style.background = '';
                        }
                    });
                } else {
                    let count = 0;
                    sortedPersonalCertificates.some(sortedPersonalCertificate => {
                        if (certificatesChecked.includes(sortedPersonalCertificate.index)) {
                            oldCertificatesCheckedLength--;
                            return false;
                        } else {
                            certificatesChecked.push(sortedPersonalCertificate.index);
                            count++;
                            return count >= maxSelectCertificates || certificatesChecked.length >= maxSelectCertificates;
                        }
                    });
                    count = oldCertificatesCheckedLength;
                    sortedPersonalCertificates.some(sortedPersonalCertificate => {
                        if (singleCheckBoxRefs.current[sortedPersonalCertificate.index]) {
                            singleCheckBoxRefs.current[sortedPersonalCertificate.index].forceUncheck();
                            count++;
                            return count >= maxSelectCertificates;
                        } else {
                            return false;
                        }
                    });
                    count = oldCertificatesCheckedLength;
                    sortedPersonalCertificates.some(sortedPersonalCertificate => {
                        if (document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString()))) {
                            document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString())).style.background = '';
                            count++;
                            return count >= maxSelectCertificates;
                        } else {
                            return false;
                        }
                    });
                }
                let count = oldCertificatesCheckedLength;
                sortedPersonalCertificates.some(sortedPersonalCertificate => {
                    if (singleCheckBoxRefs.current[sortedPersonalCertificate.index]) {
                        singleCheckBoxRefs.current[sortedPersonalCertificate.index].forceCheck();
                        count++;
                        return count >= maxSelectCertificates;
                    } else {
                        return false;
                    }
                });
                count = oldCertificatesCheckedLength;
                sortedPersonalCertificates.some(sortedPersonalCertificate => {
                    if (document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString()))) {
                        document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString())).style.background = selectedCertificateBackgroundColor;
                        count++;
                        return count >= maxSelectCertificates;
                    } else {
                        return false;
                    }
                });
            } else {
                if (sortedPersonalCertificates.length === personalCertificates.length) {
                    certificatesChecked.splice(0, certificatesChecked.length);
                } else {
                    let count = 0;
                    sortedPersonalCertificates.some(sortedPersonalCertificate => {
                        if (certificatesChecked.includes(sortedPersonalCertificate.index)) {
                            certificatesChecked.splice(certificatesChecked.indexOf(sortedPersonalCertificate.index), 1);
                            count++;
                            return count >= maxSelectCertificates || certificatesChecked.length === 0;
                        } else {
                            return false;
                        }
                    });
                }
                singleCheckBoxRefs.current.forEach(singleCheckBoxRef => singleCheckBoxRef && singleCheckBoxRef.forceUncheck());
                sortedPersonalCertificates.forEach(sortedPersonalCertificate => {
                    if (document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString()))) {
                        document.getElementById(certificateIndexPattern.replace('{index}', sortedPersonalCertificate.index.toString())).style.background = '';
                    }
                });
            }
            if (checkedRef.current) {
                checkedRef.current.innerText = certificatesChecked.length;
            }
        } else {
            if (certificatesChecked.length >= sortedPersonalCertificates.length) {
                let shouldCheck = true;
                sortedPersonalCertificates.some(sortedPersonalCertificate => {
                    if (!certificatesChecked.includes(sortedPersonalCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            shake();
        }
    }

    function onChangeSingleCheckBox(event, certificateIndex) {
        if (certificatesChecked.length < maxSelectCertificates || !event.target.checked) {
            if (certificatesChecked.includes(certificateIndex)) {
                certificatesChecked.splice(certificatesChecked.indexOf(certificateIndex), 1);
                document.getElementById(certificateIndexPattern.replace('{index}', certificateIndex)).style.background = '';
            } else {
                certificatesChecked.push(certificateIndex);
                document.getElementById(certificateIndexPattern.replace('{index}', certificateIndex)).style.background = selectedCertificateBackgroundColor;
            }
            if (certificatesChecked.length >= sortedPersonalCertificates.length) {
                let shouldCheck = true;
                sortedPersonalCertificates.some(sortedPersonalCertificate => {
                    if (!certificatesChecked.includes(sortedPersonalCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            if (checkedRef.current) {
                checkedRef.current.innerText = certificatesChecked.length;
            }
        } else {
            singleCheckBoxRefs.current[certificateIndex] && singleCheckBoxRefs.current[certificateIndex].forceUncheck();
            shake();
        }
    }

    function shake() {
        for (let i = 0; i < checkedRef.current.parentElement.children.length; i++) {
            checkedRef.current.parentElement.children[i].style.color = shakeColor;
        }
        checkedRef.current.parentElement.style.animationDuration = checkedDefaultAnimationDuration;
        clearTimeout(shakeTimer);
        shakeTimer = setTimeout(() => {
            for (let i = 0; i < checkedRef.current.parentElement.children.length; i++) {
                checkedRef.current.parentElement.children[i].style.color = checkedDefaultColor;
            }
            checkedRef.current.parentElement.style.animationDuration = checkedDefaultEmptyDuration;
        }, shakeDuration);
    }

    function onDownloadCertificates() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shake();
        } else {
            if (isIOS && !isSafari) {
                errorGetCertificates(700);
            } else {
                if (document.getElementById(processingIndex)) {
                    hideProcessing = false;
                    document.getElementById(processingIndex).hidden = false;
                }
                setTimeout(() => {
                    const jsZip = new JSZip();
                    const getCertificatesRequestsPromises = [];
                    certificatesChecked.forEach(certificateChecked => {
                        const request = {
                            serial: personalCertificates[certificateChecked - 1].serial,
                        };
                        const getCertificatesRequestPromise = axios.post(backPaths.GET_CERTIFICATES_BACK_PATH, request)
                            .then(response => {
                                console.log('Get certificates response = ', response);
                                if (response.data.sign) {
                                    const signCertificateName = response.data.serial + '_sign.crt';
                                    const signCertificateData = response.data.sign;
                                    jsZip.file(signCertificateName, signCertificateData, {base64: true});
                                }
                                if (response.data.enc) {
                                    const encCertificateName = response.data.serialEnc + '_enc.crt';
                                    const encCertificateData = response.data.enc;
                                    jsZip.file(encCertificateName, encCertificateData, {base64: true});
                                }
                            })
                            .catch(error => {
                                console.log('Get certificates response = ', error.response);
                                return Promise.reject({
                                    code: 710,
                                    requestId: error.response.headers.requestid,
                                });
                            });
                        getCertificatesRequestsPromises.push(getCertificatesRequestPromise);
                    });
                    Promise.all(getCertificatesRequestsPromises)
                        .then(() => {
                            jsZip.generateAsync({type: 'blob'})
                                .then(zip => {
                                    saveAs(zip, 'certificates.zip');
                                    console.log('Certificates downloaded');
                                    if (document.getElementById(processingIndex)) {
                                        hideProcessing = true;
                                        document.getElementById(processingIndex).hidden = true;
                                    }
                                })
                                .catch(error => {
                                    console.log('Generate certificates zip error = ', error);
                                    errorGetCertificates(720);
                                    if (document.getElementById(processingIndex)) {
                                        hideProcessing = true;
                                        document.getElementById(processingIndex).hidden = true;
                                    }
                                });
                        })
                        .catch(error => {
                            errorGetCertificates(error.code, error.requestId);
                            if (document.getElementById(processingIndex)) {
                                hideProcessing = true;
                                document.getElementById(processingIndex).hidden = true;
                            }
                        });
                }, 25);
            }
        }
    }

    function onDownloadPdfs() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shake();
        } else {
            if (isIOS && !isSafari) {
                errorGetPdf(800);
            } else {
                if (document.getElementById(processingIndex)) {
                    hideProcessing = false;
                    document.getElementById(processingIndex).hidden = false;
                }
                setTimeout(() => {
                    const jsZip = new JSZip();
                    const getPdfRequestsPromises = [];
                    certificatesChecked.forEach(certificateChecked => {
                        const request = {
                            serial: personalCertificates[certificateChecked - 1].serial,
                        };
                        const getPdfRequestPromise = axios.post(backPaths.GET_PDF_BACK_PATH, request)
                            .then(response => {
                                console.log('Get pdf response = ', response);
                                const { pdf, agreement, act } = response.data;
                                if (pdf) {
                                    const pdfCertInfoName = request.serial + '.pdf';
                                    jsZip.file(pdfCertInfoName, pdf, {base64: true});

                                    if (agreement) {
                                        const pdfAgreementName = localizations.personalCertificatesDownloadedAgreement + request.serial + '.pdf';
                                        jsZip.file(pdfAgreementName, agreement, {base64: true});
                                    }
                                    if (act) {
                                        const pdfActName = localizations.personalCertificatesDownloadedAct + request.serial + '.pdf';
                                        jsZip.file(pdfActName, act, {base64: true});
                                    }
                                }
                            })
                            .catch(error => {
                                console.log('Get pdf response = ', error.response);
                                return Promise.reject({
                                    code: 810,
                                    requestId: error.response.headers.requestid,
                                });
                            });
                        getPdfRequestsPromises.push(getPdfRequestPromise);
                    });
                    Promise.all(getPdfRequestsPromises)
                        .then(() => {
                            jsZip.generateAsync({type: 'blob'})
                                .then(zip => {
                                    saveAs(zip, 'pdfs.zip');
                                    console.log('PDFs downloaded');
                                    if (document.getElementById(processingIndex)) {
                                        hideProcessing = true;
                                        document.getElementById(processingIndex).hidden = true;
                                    }
                                })
                                .catch(error => {
                                    console.log('Generate pdfs zip error = ', error);
                                    errorGetPdf(820);
                                    if (document.getElementById(processingIndex)) {
                                        hideProcessing = true;
                                        document.getElementById(processingIndex).hidden = true;
                                    }
                                });
                        })
                        .catch(error => {
                            errorGetPdf(error.code, error.requestId);
                            if (document.getElementById(processingIndex)) {
                                hideProcessing = true;
                                document.getElementById(processingIndex).hidden = true;
                            }
                        });
                }, 25);
            }
        }
    }

    function onRevokeCertificates() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shake();
        } else {
            checkAuthorizedToken(() => {
                const serials = [];
                certificatesChecked.forEach(certificateChecked => {
                    serials.push(personalCertificates[certificateChecked - 1].serial);
                });
                defineCompromisedId();
                const request = {
                    token: authorizedToken,
                    serials: serials,
                };
                if (compromisedId) {
                    request.compromised = compromisedId;
                }
                axios.post(backPaths.REVOCATION_REQUEST_BACK_PATH, request)
                    .then(response => {
                        console.log('Revocation request response = ', response);
                        if (activeLanguage && authorizedToken) {
                            frameRef.current.src = servicePaths.REVOCATION_SERVICE_PATH.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken);
                            console.log(`Revocation frame url = ${frameRef.current.src}`);
                            blurRef.current.style.display = 'flex';
                        } else {
                            errorRevocationRequest(910);
                        }
                    })
                    .catch(error => {
                        console.log('Revocation request response = ', error.response);
                        errorRevocationRequest(900, error.response.headers.requestid);
                    });
            });
        }
    }

    function onUnpublicateCertificates() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shake();
        } else {
            checkAuthorizedToken(() => {
                const serials = [];
                certificatesChecked.forEach(certificateChecked => {
                    serials.push(personalCertificates[certificateChecked - 1].serial);
                });
                const request = {
                    token: authorizedToken,
                    serials: serials,
                };
                axios.post(backPaths.UNPUBLICATION_REQUEST_BACK_PATH, request)
                    .then(response => {
                        console.log('Unpublication request response = ', response);
                        if (activeLanguage && authorizedToken) {
                            frameRef.current.src = servicePaths.UNPUBLICATION_SERVICE_PATH.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken);
                            console.log(`Unpublication frame url = ${frameRef.current.src}`);
                            blurRef.current.style.display = 'flex';
                        } else {
                            errorUnpublicationRequest(1010);
                        }
                    })
                    .catch(error => {
                        console.log('Unpublication request response = ', error.response);
                        errorUnpublicationRequest(1000, error.response.headers.requestid);
                    });
            });
        }
    }

    function onGenerateCertificates() {
        checkAuthorizedToken(() => {
            if (activeLanguage && authorizedToken) {
                frameRef.current.src = servicePaths.GENERATION_SERVICE_PATH.replaceAll('{language}', activeLanguage).replaceAll('{auth}', authorizedToken);
                console.log(`Generation frame url = ${frameRef.current.src}`);
                blurRef.current.style.display = 'flex';
            } else {
                errorGenerationRequest(1100);
            }
        });
    }

    function closeModals() {
        blurRef.current.style.display = 'none';
    }

    return (
        <div className={styles.PersonalCertificates}>
            <BrowserView viewClassName={styles.Browser}>
                {
                    isAuthorized && <div id={personalCertificatesLoadingIndex}
                                         style={{'display': ''}}
                                         className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length === 0 && <div id={personalCertificatesEmptyIndex}
                                                                              style={{'display': 'none'}}
                                                                              className={styles.Empty}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesEmpty}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length > 0 && <div id={personalCertificatesSearchIndex}
                                                                            style={{'display': 'none'}}
                                                                            className={styles.Container}>
                        <input className={styles.Search}
                               placeholder={localizations.personalCertificatesSearch}
                               onChange={onSearch}/>
                        <div className={styles.FiltersAndSorts}>
                            <select className={styles.Filter}
                                    value={statusFilterType}
                                    onChange={(event) => onStatusFilter(event.target.value)}>
                                {
                                    statusFilters.map((statusFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={statusFilter}>
                                                {
                                                    statusFilter === 'active'
                                                        ? localizations.personalCertificatesActiveStatusFilter
                                                        : statusFilter === 'revoked'
                                                            ? localizations.personalCertificatesRevokedStatusFilter
                                                            : statusFilter === 'blocked'
                                                                ? localizations.personalCertificatesBlockedStatusFilter
                                                                : localizations.personalCertificatesAllStatusFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={typeFilterType}
                                    onChange={(event) => onTypeFilter(event.target.value)}>
                                {
                                    typeFilters.map((typeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={typeFilter}>
                                                {
                                                    typeFilter === 'sign'
                                                        ? localizations.personalCertificatesSignTypeFilter
                                                        : typeFilter === 'stamp'
                                                            ? localizations.personalCertificatesStampTypeFilter
                                                            : localizations.personalCertificatesAllTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={keyFilterType}
                                    onChange={(event) => onKeyFilter(event.target.value)}>
                                {
                                    keyFilters.map((keyFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={keyFilter}>
                                                {
                                                    keyFilter === 'jks'
                                                        ? localizations.personalCertificatesJksKeyFilter
                                                        : keyFilter === 'smartId'
                                                            ? localizations.personalCertificatesSmartIdKeyFilter
                                                            : localizations.personalCertificatesAllKeyFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={userTypeFilterType}
                                    onChange={(event) => onUserTypeFilter(event.target.value)}>
                                {
                                    userTypeFilters.map((userTypeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={userTypeFilter}>
                                                {
                                                    userTypeFilter === 'individual'
                                                        ? localizations.personalCertificatesIndividualUserTypeFilter
                                                        : userTypeFilter === 'legal'
                                                            ? localizations.personalCertificatesLegalUserTypeFilter
                                                            : userTypeFilter === 'employee'
                                                                ? localizations.personalCertificatesEmployeeUserTypeFilter
                                                                : localizations.personalCertificatesAllUserTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            {
                                personalData.organizations.length > 0 && <select className={styles.Filter}
                                                                                 value={orgNameFilterType}
                                                                                 onChange={(event) => onOrgNameFilter(event.target.value)}>
                                    {
                                        orgNameFilters.map((orgNameFilter, index) => {
                                            return (
                                                <option key={index}
                                                        className={styles.Option}
                                                        value={orgNameFilter}>
                                                    {
                                                        orgNameFilter === 'all'
                                                            ? localizations.personalCertificatesAllOrgNameFilter
                                                            : orgNameFilter
                                                    }
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            }
                            <select className={styles.Sort}
                                    value={sortType}
                                    onChange={(event) => onSort(event.target.value)}>
                                {
                                    sorts.map((sort, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={sort}>
                                                {
                                                    sort === 'startDate'
                                                        ? localizations.personalCertificatesStartDateSort
                                                        : sort === 'revokedDate'
                                                            ? localizations.personalCertificatesRevokedDateSort
                                                            : sort === 'status'
                                                                ? localizations.personalCertificatesStatusSort
                                                                : sort === 'type'
                                                                    ? localizations.personalCertificatesTypeSort
                                                                    : sort === 'key'
                                                                        ? localizations.personalCertificatesKeySort
                                                                        : sort === 'owner'
                                                                            ? localizations.personalCertificatesOwnerSort
                                                                            : sort === 'orgName'
                                                                                ? localizations.personalCertificatesOrgNameSort
                                                                                : sort === 'userType'
                                                                                    ? localizations.personalCertificatesUserTypeSort
                                                                                    : localizations.personalCertificatesDefaultSort
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className={styles.Content}>
                            {
                                sortedPersonalCertificates.length === 0 && <div id={notFoundIndex}
                                                                                className={styles.NotFound}>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.personalCertificatesNotFound}
                                    </Row>
                                </div>
                            }
                            {
                                sortedPersonalCertificates.length > 0 && <table className={styles.PersonalCertificate}>
                                    <thead>
                                    <tr>
                                        <th>
                                            <Tooltip messages={[localizations.personalCertificatesMultiCheckBox]}
                                                     placement={'top'}>
                                                <div className={styles.MultiCheckBoxContainer}>
                                                    <CheckBox id={0}
                                                              ref={multiplyCheckBoxRef}
                                                              onChange={onChangeMultiCheckBox}/>
                                                </div>
                                            </Tooltip>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesOwner}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesSerial}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesStatus}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesValidity}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesType}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesKey}
                                            </Headline>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sortedPersonalCertificates.map(displayedPersonalCertificate => {
                                            return (
                                                <tr id={certificateIndexPattern.replace('{index}', displayedPersonalCertificate.index.toString())}
                                                    key={displayedPersonalCertificate.index}>
                                                    <td>
                                                        <CheckBox id={displayedPersonalCertificate.index}
                                                                  ref={(element) => singleCheckBoxRefs.current[displayedPersonalCertificate.index] = element}
                                                                  onChange={(event) => onChangeSingleCheckBox(event, displayedPersonalCertificate.index)}/>
                                                    </td>
                                                    <td>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.owner}
                                                        </Row>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.orgName}
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.serial}
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <div style={{flexDirection: 'column'}}
                                                             className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.status === 3
                                                                        ? localizations.personalCertificatesStatusBlocked
                                                                        : displayedPersonalCertificate.status === 2
                                                                            ? localizations.personalCertificatesStatusRevoked
                                                                            : localizations.personalCertificatesStatusActive
                                                                }
                                                            </Row>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}>
                                                                {displayedPersonalCertificate.isRevoked && displayedPersonalCertificate.revokedDate}
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.startDate}
                                                            </Row>
                                                            &nbsp;
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.endDate}
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.isSign
                                                                        ? localizations.personalCertificatesTypeSign
                                                                        : localizations.personalCertificatesTypeStamp
                                                                }
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.IconContainer}>
                                                            {
                                                                displayedPersonalCertificate.type === 2
                                                                    ? displayedPersonalCertificate.isCanBeUsed
                                                                        ? displayedPersonalCertificate.isSign
                                                                            ? <Tooltip
                                                                                messages={[localizations.personalCertificatesSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdSignIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                            : <Tooltip
                                                                                messages={[localizations.personalCertificatesStampSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdStampIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                        : displayedPersonalCertificate.isSign
                                                                            ? <Tooltip
                                                                                messages={[localizations.personalCertificatesInactiveSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdInactiveSignIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                            : <Tooltip
                                                                                messages={[localizations.personalCertificatesInactiveSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdInactiveStampIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                    : displayedPersonalCertificate.isSign
                                                                        ? <Tooltip
                                                                            messages={[localizations.personalCertificatesSignJKS]}
                                                                            placement={'top'}>
                                                                            <JksSignIcon className={styles.Icon}/>
                                                                        </Tooltip>
                                                                        : <Tooltip
                                                                            messages={[localizations.personalCertificatesStampJKS]}
                                                                            placement={'top'}>
                                                                            <JksStampIcon className={styles.Icon}/>
                                                                        </Tooltip>
                                                            }
                                                            {
                                                                displayedPersonalCertificate.isPublic
                                                                    ? <Tooltip
                                                                        messages={[localizations.personalCertificatesPublic]}
                                                                        placement={'top'}>
                                                                        <PublicCertificateIcon className={styles.PublicationIcon}/>
                                                                    </Tooltip>
                                                                    : <Tooltip
                                                                        messages={[localizations.personalCertificatesUnpublic]}
                                                                        placement={'top'}>
                                                                        <UnpublicCertificateIcon
                                                                            className={styles.PublicationIcon}/>
                                                                    </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div className={styles.ActionPanel}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                            <div className={styles.Container}>
                                <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.personalCertificatesChecked}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span ref={checkedRef}
                                          className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className={styles.CheckedMax}>
                                        {localizations.personalCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                                </div>
                                <div className={styles.ActionsMenu}>
                                    <div className={styles.Action}
                                         onClick={onDownloadCertificates}>
                                        <DownloadCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onDownloadPdfs}>
                                        <DownloadPdfsIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadPdfsAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onRevokeCertificates}>
                                        <RevokeCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesRevokeCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onUnpublicateCertificates}>
                                        <UnpublicateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesUnpublicateCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onGenerateCertificates}>
                                        <GenerateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesGenerateCertificatesAction}
                                        </Row>
                                    </div>
                                </div>
                                <div ref={blurRef}
                                     className={styles.Blur}>
                                    <iframe ref={frameRef}
                                            className={styles.Frame}
                                            title={'service'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.personalCertificatesAuthorization}
                        </div>
                        <button className={styles.Button}
                                onClick={login}>
                            {localizations.personalCertificatesAuthorizationButton}
                        </button>
                    </div>
                }
                <div id={processingIndex}
                     className={styles.Processing}
                     hidden={hideProcessing}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                {
                    isAuthorized && <div id={personalCertificatesLoadingIndex}
                                         style={{'display': ''}}
                                         className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length === 0 && <div id={personalCertificatesEmptyIndex}
                                                                              style={{'display': 'none'}}
                                                                              className={styles.Empty}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesEmpty}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length > 0 && <div id={personalCertificatesSearchIndex}
                                                                            style={{'display': 'none'}}
                                                                            className={styles.Container}>
                        <input className={styles.Search}
                               placeholder={localizations.personalCertificatesSearch}
                               onChange={onSearch}/>
                        <div className={styles.FiltersAndSorts}>
                            <select className={styles.Filter}
                                    value={statusFilterType}
                                    onChange={(event) => onStatusFilter(event.target.value)}>
                                {
                                    statusFilters.map((statusFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={statusFilter}>
                                                {
                                                    statusFilter === 'active'
                                                        ? localizations.personalCertificatesActiveStatusFilter
                                                        : statusFilter === 'revoked'
                                                            ? localizations.personalCertificatesRevokedStatusFilter
                                                            : statusFilter === 'blocked'
                                                                ? localizations.personalCertificatesBlockedStatusFilter
                                                                : localizations.personalCertificatesAllStatusFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={typeFilterType}
                                    onChange={(event) => onTypeFilter(event.target.value)}>
                                {
                                    typeFilters.map((typeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={typeFilter}>
                                                {
                                                    typeFilter === 'sign'
                                                        ? localizations.personalCertificatesSignTypeFilter
                                                        : typeFilter === 'stamp'
                                                            ? localizations.personalCertificatesStampTypeFilter
                                                            : localizations.personalCertificatesAllTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={keyFilterType}
                                    onChange={(event) => onKeyFilter(event.target.value)}>
                                {
                                    keyFilters.map((keyFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={keyFilter}>
                                                {
                                                    keyFilter === 'jks'
                                                        ? localizations.personalCertificatesJksKeyFilter
                                                        : keyFilter === 'smartId'
                                                            ? localizations.personalCertificatesSmartIdKeyFilter
                                                            : localizations.personalCertificatesAllKeyFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={userTypeFilterType}
                                    onChange={(event) => onUserTypeFilter(event.target.value)}>
                                {
                                    userTypeFilters.map((userTypeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={userTypeFilter}>
                                                {
                                                    userTypeFilter === 'individual'
                                                        ? localizations.personalCertificatesIndividualUserTypeFilter
                                                        : userTypeFilter === 'legal'
                                                            ? localizations.personalCertificatesLegalUserTypeFilter
                                                            : userTypeFilter === 'employee'
                                                                ? localizations.personalCertificatesEmployeeUserTypeFilter
                                                                : localizations.personalCertificatesAllUserTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            {
                                personalData.organizations.length > 0 && <select className={styles.Filter}
                                                                                 value={orgNameFilterType}
                                                                                 onChange={(event) => onOrgNameFilter(event.target.value)}>
                                    {
                                        orgNameFilters.map((orgNameFilter, index) => {
                                            return (
                                                <option key={index}
                                                        className={styles.Option}
                                                        value={orgNameFilter}>
                                                    {
                                                        orgNameFilter === 'all'
                                                            ? localizations.personalCertificatesAllOrgNameFilter
                                                            : orgNameFilter
                                                    }
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            }
                            <select className={styles.Sort}
                                    value={sortType}
                                    onChange={(event) => onSort(event.target.value)}>
                                {
                                    sorts.map((sort, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={sort}>
                                                {
                                                    sort === 'startDate'
                                                        ? localizations.personalCertificatesStartDateSort
                                                        : sort === 'revokedDate'
                                                            ? localizations.personalCertificatesRevokedDateSort
                                                            : sort === 'status'
                                                                ? localizations.personalCertificatesStatusSort
                                                                : sort === 'type'
                                                                    ? localizations.personalCertificatesTypeSort
                                                                    : sort === 'key'
                                                                        ? localizations.personalCertificatesKeySort
                                                                        : sort === 'owner'
                                                                            ? localizations.personalCertificatesOwnerSort
                                                                            : sort === 'orgName'
                                                                                ? localizations.personalCertificatesOrgNameSort
                                                                                : sort === 'userType'
                                                                                    ? localizations.personalCertificatesUserTypeSort
                                                                                    : localizations.personalCertificatesDefaultSort
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className={styles.Content}>
                            {
                                sortedPersonalCertificates.length === 0 && <div id={notFoundIndex}
                                                                                className={styles.NotFound}>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.personalCertificatesNotFound}
                                    </Row>
                                </div>
                            }
                            {
                                sortedPersonalCertificates.length > 0 && <table className={styles.PersonalCertificate}>
                                    <thead>
                                    <tr>
                                        <th>
                                            <Tooltip messages={[localizations.personalCertificatesMultiCheckBox]}
                                                     placement={'top'}>
                                                <div className={styles.MultiCheckBoxContainer}>
                                                    <CheckBox id={0}
                                                              ref={multiplyCheckBoxRef}
                                                              onChange={onChangeMultiCheckBox}/>
                                                </div>
                                            </Tooltip>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesOwner}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesSerial}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesStatus}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesValidity}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesType}
                                            </Headline>
                                        </th>
                                        <th>
                                            <Headline contentMargin={'0'}
                                                      contentFontFamily={'Inter, sans-serif'}
                                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                                      contentFontSize={'var(--body2-bold-font-size)'}
                                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                {localizations.personalCertificatesKey}
                                            </Headline>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sortedPersonalCertificates.map(displayedPersonalCertificate => {
                                            return (
                                                <tr id={certificateIndexPattern.replace('{index}', displayedPersonalCertificate.index.toString())}
                                                    key={displayedPersonalCertificate.index}>
                                                    <td>
                                                        <CheckBox id={displayedPersonalCertificate.index}
                                                                  ref={(element) => singleCheckBoxRefs.current[displayedPersonalCertificate.index] = element}
                                                                  onChange={(event) => onChangeSingleCheckBox(event, displayedPersonalCertificate.index)}/>
                                                    </td>
                                                    <td>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.owner}
                                                        </Row>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.orgName}
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.serial}
                                                        </Row>
                                                    </td>
                                                    <td>
                                                        <div style={{flexDirection: 'column'}}
                                                             className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.status === 3
                                                                        ? localizations.personalCertificatesStatusBlocked
                                                                        : displayedPersonalCertificate.status === 2
                                                                            ? localizations.personalCertificatesStatusRevoked
                                                                            : localizations.personalCertificatesStatusActive
                                                                }
                                                            </Row>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.isRevoked && displayedPersonalCertificate.revokedDate}
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.startDate}
                                                            </Row>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.endDate}
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.isSign
                                                                        ? localizations.personalCertificatesTypeSign
                                                                        : localizations.personalCertificatesTypeStamp
                                                                }
                                                            </Row>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className={styles.IconContainer}>
                                                            {
                                                                displayedPersonalCertificate.type === 2
                                                                    ? displayedPersonalCertificate.isCanBeUsed
                                                                        ? displayedPersonalCertificate.isSign
                                                                            ? <Tooltip
                                                                                messages={[localizations.personalCertificatesSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdSignIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                            : <Tooltip
                                                                                messages={[localizations.personalCertificatesStampSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdStampIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                        : displayedPersonalCertificate.isSign
                                                                            ? <Tooltip
                                                                                messages={[localizations.personalCertificatesInactiveSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdInactiveSignIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                            : <Tooltip
                                                                                messages={[localizations.personalCertificatesInactiveSignSmartID]}
                                                                                placement={'top'}>
                                                                                <SmartIdInactiveStampIcon className={styles.Icon}/>
                                                                            </Tooltip>
                                                                    : displayedPersonalCertificate.isSign
                                                                        ? <Tooltip
                                                                            messages={[localizations.personalCertificatesSignJKS]}
                                                                            placement={'top'}>
                                                                            <JksSignIcon className={styles.Icon}/>
                                                                        </Tooltip>
                                                                        : <Tooltip
                                                                            messages={[localizations.personalCertificatesStampJKS]}
                                                                            placement={'top'}>
                                                                            <JksStampIcon className={styles.Icon}/>
                                                                        </Tooltip>
                                                            }
                                                            {
                                                                displayedPersonalCertificate.isPublic
                                                                    ? <Tooltip
                                                                        messages={[localizations.personalCertificatesPublic]}
                                                                        placement={'top'}>
                                                                        <PublicCertificateIcon className={styles.PublicationIcon}/>
                                                                    </Tooltip>
                                                                    : <Tooltip
                                                                        messages={[localizations.personalCertificatesUnpublic]}
                                                                        placement={'top'}>
                                                                        <UnpublicCertificateIcon
                                                                            className={styles.PublicationIcon}/>
                                                                    </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div className={styles.ActionPanel}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                            <div className={styles.Container}>
                                <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.personalCertificatesChecked}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span ref={checkedRef}
                                          className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className={styles.CheckedMax}>
                                        {localizations.personalCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                                </div>
                                <div className={styles.ActionsMenu}>
                                    <div className={styles.Action}
                                         onClick={onDownloadCertificates}>
                                        <DownloadCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onDownloadPdfs}>
                                        <DownloadPdfsIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadPdfsAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onRevokeCertificates}>
                                        <RevokeCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesRevokeCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onUnpublicateCertificates}>
                                        <UnpublicateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesUnpublicateCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onGenerateCertificates}>
                                        <GenerateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesGenerateCertificatesAction}
                                        </Row>
                                    </div>
                                </div>
                                <div ref={blurRef}
                                     className={styles.Blur}>
                                    <iframe ref={frameRef}
                                            className={styles.Frame}
                                            title={'service'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.personalCertificatesAuthorization}
                        </div>
                        <button className={styles.Button}
                                onClick={login}>
                            {localizations.personalCertificatesAuthorizationButton}
                        </button>
                    </div>
                }
                <div id={processingIndex}
                     className={styles.Processing}
                     hidden={hideProcessing}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                {
                    isAuthorized && <div id={personalCertificatesLoadingIndex}
                                         style={{'display': ''}}
                                         className={styles.Loading}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesLoading}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length === 0 && <div id={personalCertificatesEmptyIndex}
                                                                              style={{'display': 'none'}}
                                                                              className={styles.Empty}>
                        <Row horizontal={true}>
                            {localizations.personalCertificatesEmpty}
                        </Row>
                    </div>
                }
                {
                    isAuthorized && personalCertificates.length > 0 && <div id={personalCertificatesSearchIndex}
                                                                            style={{'display': 'none'}}
                                                                            className={styles.Container}>
                        <input className={styles.Search}
                               placeholder={localizations.personalCertificatesSearch}
                               onChange={onSearch}/>
                        <div className={styles.FiltersAndSorts}>
                            <select className={styles.Filter}
                                    value={statusFilterType}
                                    onChange={(event) => onStatusFilter(event.target.value)}>
                                {
                                    statusFilters.map((statusFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={statusFilter}>
                                                {
                                                    statusFilter === 'active'
                                                        ? localizations.personalCertificatesActiveStatusFilter
                                                        : statusFilter === 'revoked'
                                                            ? localizations.personalCertificatesRevokedStatusFilter
                                                            : statusFilter === 'blocked'
                                                                ? localizations.personalCertificatesBlockedStatusFilter
                                                                : localizations.personalCertificatesAllStatusFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={typeFilterType}
                                    onChange={(event) => onTypeFilter(event.target.value)}>
                                {
                                    typeFilters.map((typeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={typeFilter}>
                                                {
                                                    typeFilter === 'sign'
                                                        ? localizations.personalCertificatesSignTypeFilter
                                                        : typeFilter === 'stamp'
                                                            ? localizations.personalCertificatesStampTypeFilter
                                                            : localizations.personalCertificatesAllTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={keyFilterType}
                                    onChange={(event) => onKeyFilter(event.target.value)}>
                                {
                                    keyFilters.map((keyFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={keyFilter}>
                                                {
                                                    keyFilter === 'jks'
                                                        ? localizations.personalCertificatesJksKeyFilter
                                                        : keyFilter === 'smartId'
                                                            ? localizations.personalCertificatesSmartIdKeyFilter
                                                            : localizations.personalCertificatesAllKeyFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <select className={styles.Filter}
                                    value={userTypeFilterType}
                                    onChange={(event) => onUserTypeFilter(event.target.value)}>
                                {
                                    userTypeFilters.map((userTypeFilter, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={userTypeFilter}>
                                                {
                                                    userTypeFilter === 'individual'
                                                        ? localizations.personalCertificatesIndividualUserTypeFilter
                                                        : userTypeFilter === 'legal'
                                                            ? localizations.personalCertificatesLegalUserTypeFilter
                                                            : userTypeFilter === 'employee'
                                                                ? localizations.personalCertificatesEmployeeUserTypeFilter
                                                                : localizations.personalCertificatesAllUserTypeFilter
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            {
                                personalData.organizations.length > 0 && <select className={styles.Filter}
                                                                                 value={orgNameFilterType}
                                                                                 onChange={(event) => onOrgNameFilter(event.target.value)}>
                                    {
                                        orgNameFilters.map((orgNameFilter, index) => {
                                            return (
                                                <option key={index}
                                                        className={styles.Option}
                                                        value={orgNameFilter}>
                                                    {
                                                        orgNameFilter === 'all'
                                                            ? localizations.personalCertificatesAllOrgNameFilter
                                                            : orgNameFilter
                                                    }
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            }
                            <select className={styles.Sort}
                                    value={sortType}
                                    onChange={(event) => onSort(event.target.value)}>
                                {
                                    sorts.map((sort, index) => {
                                        return (
                                            <option key={index}
                                                    className={styles.Option}
                                                    value={sort}>
                                                {
                                                    sort === 'startDate'
                                                        ? localizations.personalCertificatesStartDateSort
                                                        : sort === 'revokedDate'
                                                            ? localizations.personalCertificatesRevokedDateSort
                                                            : sort === 'status'
                                                                ? localizations.personalCertificatesStatusSort
                                                                : sort === 'type'
                                                                    ? localizations.personalCertificatesTypeSort
                                                                    : sort === 'key'
                                                                        ? localizations.personalCertificatesKeySort
                                                                        : sort === 'owner'
                                                                            ? localizations.personalCertificatesOwnerSort
                                                                            : sort === 'orgName'
                                                                                ? localizations.personalCertificatesOrgNameSort
                                                                                : sort === 'userType'
                                                                                    ? localizations.personalCertificatesUserTypeSort
                                                                                    : localizations.personalCertificatesDefaultSort
                                                }
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        {
                            sortedPersonalCertificates.length > 0 && <div className={styles.MultiCheckBox}>
                                <CheckBox id={0}
                                          ref={multiplyCheckBoxRef}
                                          onChange={onChangeMultiCheckBox}/>
                                <div className={styles.Text}>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.personalCertificatesMultiCheckBox}
                                    </Row>
                                </div>
                            </div>
                        }
                        <div className={styles.Content}>
                            {
                                sortedPersonalCertificates.length === 0 && <div id={notFoundIndex}
                                                                                className={styles.NotFound}>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.personalCertificatesNotFound}
                                    </Row>
                                </div>
                            }
                            {
                                sortedPersonalCertificates.length > 0 && sortedPersonalCertificates.map(displayedPersonalCertificate => {
                                    return (
                                        <div key={displayedPersonalCertificate.index}
                                             className={styles.PersonalCertificate}>
                                            <div
                                                id={certificateIndexPattern.replace('{index}', displayedPersonalCertificate.index.toString())}
                                                className={styles.Card}>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <CheckBox id={displayedPersonalCertificate.index}
                                                                  ref={(element) => singleCheckBoxRefs.current[displayedPersonalCertificate.index] = element}
                                                                  onChange={(event) => onChangeSingleCheckBox(event, displayedPersonalCertificate.index)}/>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                                  contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                            {`${displayedPersonalCertificate.owner} ${displayedPersonalCertificate.orgName}`}
                                                        </Headline>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesSerial}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedPersonalCertificate.serial}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesStatus}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.status === 3
                                                                        ? localizations.personalCertificatesStatusBlocked
                                                                        : displayedPersonalCertificate.status === 2
                                                                            ? localizations.personalCertificatesStatusRevoked
                                                                            : localizations.personalCertificatesStatusActive
                                                                }
                                                                &nbsp;
                                                            </Row>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.isRevoked && displayedPersonalCertificate.revokedDate}
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesValidity}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <div className={styles.Flex}>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.startDate}
                                                                &nbsp;-&nbsp;
                                                            </Row>
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {displayedPersonalCertificate.endDate}
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesType}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                displayedPersonalCertificate.isSign
                                                                    ? localizations.personalCertificatesTypeSign
                                                                    : localizations.personalCertificatesTypeStamp
                                                            }
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesKey}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <div className={styles.Inline}>
                                                            {
                                                                displayedPersonalCertificate.type === 2
                                                                    ? displayedPersonalCertificate.isCanBeUsed
                                                                        ? displayedPersonalCertificate.isSign
                                                                            ? <SmartIdSignIcon className={styles.Icon}/>
                                                                            : <SmartIdStampIcon className={styles.Icon}/>
                                                                        : displayedPersonalCertificate.isSign
                                                                            ? <SmartIdInactiveSignIcon className={styles.Icon}/>
                                                                            : <SmartIdInactiveStampIcon className={styles.Icon}/>
                                                                    : displayedPersonalCertificate.isSign
                                                                        ? <JksSignIcon className={styles.Icon}/>
                                                                        : <JksStampIcon className={styles.Icon}/>
                                                            }
                                                            &nbsp;
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.type === 2
                                                                        ? displayedPersonalCertificate.isCanBeUsed
                                                                            ? displayedPersonalCertificate.isSign
                                                                                ? localizations.personalCertificatesSignSmartID
                                                                                : localizations.personalCertificatesStampSmartID
                                                                            : localizations.personalCertificatesInactiveSignSmartID
                                                                        : displayedPersonalCertificate.isSign
                                                                            ? localizations.personalCertificatesSignJKS
                                                                            : localizations.personalCertificatesStampJKS
                                                                }
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.Row}>
                                                    <div className={styles.Left}>
                                                        <Headline contentMargin={'0'}
                                                                  contentFontFamily={'Inter, sans-serif'}
                                                                  contentFontWeight={'var(--caption-bold-font-weight)'}
                                                                  contentFontSize={'var(--caption-bold-font-size)'}
                                                                  contentLineHeight={'var(--caption-bold-line-height)'}
                                                                  contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                                  contentColor={'var(--050-black)'}>
                                                            {localizations.personalCertificatesPublication}
                                                        </Headline>
                                                    </div>
                                                    <div className={styles.Right}>
                                                        <div className={styles.Inline}>
                                                            {
                                                                displayedPersonalCertificate.isPublic
                                                                    ?
                                                                    <PublicCertificateIcon className={styles.PublicationIcon}/>
                                                                    : <UnpublicCertificateIcon
                                                                        className={styles.PublicationIcon}/>
                                                            }
                                                            &nbsp;
                                                            <Row horizontal={true}
                                                                 contentFontSize={'var(--body2-font-size)'}
                                                                 contentColor={displayedPersonalCertificate.isCanBeUsed ? '' : 'var(--030-black)'}
                                                                 contentLineHeight={'var(--body2-line-height)'}>
                                                                {
                                                                    displayedPersonalCertificate.isPublic
                                                                        ? localizations.personalCertificatesPublic
                                                                        : localizations.personalCertificatesUnpublic
                                                                }
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className={styles.ActionPanel}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                            <div className={styles.Container}>
                                <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.personalCertificatesChecked}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span ref={checkedRef}
                                          className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className={styles.CheckedMax}>
                                        {localizations.personalCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                                </div>
                                <div className={styles.ActionsMenu}>
                                    <div className={styles.Action}
                                         onClick={onDownloadCertificates}>
                                        <DownloadCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onDownloadPdfs}>
                                        <DownloadPdfsIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesDownloadPdfsAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onRevokeCertificates}>
                                        <RevokeCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesRevokeCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onUnpublicateCertificates}>
                                        <UnpublicateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesUnpublicateCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onGenerateCertificates}>
                                        <GenerateCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.personalCertificatesGenerateCertificatesAction}
                                        </Row>
                                    </div>
                                </div>
                                <div ref={blurRef}
                                     className={styles.Blur}>
                                    <iframe ref={frameRef}
                                            className={styles.Frame}
                                            title={'service'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !isAuthorized && <div className={styles.Authorization}>
                        <UnauthorizedUserIcon className={styles.Icon}/>
                        <div className={styles.Text}>
                            {localizations.personalCertificatesAuthorization}
                        </div>
                        <button className={styles.Button}
                                onClick={login}>
                            {localizations.personalCertificatesAuthorizationButton}
                        </button>
                    </div>
                }
                <div id={processingIndex}
                     className={styles.Processing}
                     hidden={hideProcessing}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
});

PersonalCertificates.propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    authorizedToken: PropTypes.string,
    activeLanguage: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    checkAuthorizedToken: PropTypes.func.isRequired,
    errorGetPersonalCertificates: PropTypes.func.isRequired,
    errorGetCertificates: PropTypes.func.isRequired,
    errorGetPdf: PropTypes.func.isRequired,
    errorRevocationRequest: PropTypes.func.isRequired,
    errorUnpublicationRequest: PropTypes.func.isRequired,
    errorGenerationRequest: PropTypes.func.isRequired,
}

export default PersonalCertificates;
