import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Contacts.module.css';
import localizations from '../../localizations/localizations';
import * as pagePaths from '../../paths/pagePaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function Contacts() {
    return (
        <div className={styles.Contacts}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Wrapper}>
                    <div className={styles.Title}>
                        <Headline contentMargin={'60px 0'}
                                  contentFontWeight={'bold'}
                                  contentFontSize={'var(--display2-font-size)'}
                                  contentLineHeight={'var(--display2-line-height)'}>
                            {localizations.contactsTitle}
                        </Headline>
                    </div>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'60px 0 0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName1}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentMargin={'60px 0 0'}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent1}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName2}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent2}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName3}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent3}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName4}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent4}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName5}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent5}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName6}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent6}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName7}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentMargin={'0'}
                                     contentColor={'var(--070-black)'}>
                                    {localizations.contactsTableRowContent7}
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName8}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    <a className={styles.Link}
                                       href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                        {localizations.contactsTableRowContent8Part1}
                                    </a>
                                </Row>
                                <Row disabledIcon={true}
                                     contentColor={'var(--070-black)'}>
                                    <a className={styles.Link}
                                       href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                        {localizations.contactsTableRowContent8Part2}
                                    </a>
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName9}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}>
                                    <Link className={styles.Link}
                                          to={pagePaths.MAIN_PAGE_PATH}>
                                        {localizations.contactsTableRowContent9}
                                    </Link>
                                </Row>
                            </div>
                        </div>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Headline contentMargin={'0'}
                                          contentFontFamily={'Inter, sans-serif'}
                                          contentFontWeight={'var(--body2-bold-font-weight)'}
                                          contentFontSize={'var(--body2-bold-font-size)'}
                                          contentLineHeight={'var(--body2-bold-line-height)'}
                                          contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                    {localizations.contactsTableRowName10}
                                </Headline>
                            </div>
                            <div className={styles.Element}>
                                <Row disabledIcon={true}>
                                    <a className={styles.Link}
                                       href={'mailto:acsk@privatbank.ua'}>
                                        {localizations.contactsTableRowContent10}
                                    </a>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Schedule}>
                    <div className={styles.Info}>
                        <Headline contentMargin={'45px 0 30px'}
                                  contentFontWeight={'var(--headline2-font-weight)'}
                                  contentFontSize={'var(--headline2-font-size)'}
                                  contentLineHeight={'var(--headline2-line-height)'}>
                            {localizations.contactsScheduleInfo1}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsScheduleInfo2}
                        </Row>
                    </div>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableName1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableContent1}
                                </Row>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableName2}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableContent2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.contactsTitle}
                    </Headline>
                </div>
                <div className={styles.Table}>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName1}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent1}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName2}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent2}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName3}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent3}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName4}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent4}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName5}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent5}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName6}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent6}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName7}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                {localizations.contactsTableRowContent7}
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName8}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                    {localizations.contactsTableRowContent8Part1}
                                </a>
                            </Row>
                            <Row disabledIcon={true}
                                 contentColor={'var(--070-black)'}>
                                <a className={styles.Link}
                                   href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                    {localizations.contactsTableRowContent8Part2}
                                </a>
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName9}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}>
                                <Link className={styles.Link}
                                      to={pagePaths.MAIN_PAGE_PATH}>
                                    {localizations.contactsTableRowContent9}
                                </Link>
                            </Row>
                        </div>
                    </div>
                    <div className={styles.Row}>
                        <div className={styles.Element}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                {localizations.contactsTableRowName10}
                            </Headline>
                        </div>
                        <div className={styles.Element}>
                            <Row disabledIcon={true}>
                                <a className={styles.Link}
                                   href={'mailto:acsk@privatbank.ua'}>
                                    {localizations.contactsTableRowContent10}
                                </a>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Schedule}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'var(--headline2-font-weight)'}
                              contentFontSize={'var(--headline2-font-size)'}
                              contentLineHeight={'var(--headline2-line-height)'}>
                        {localizations.contactsScheduleInfo1}
                    </Headline>
                    <Row disabledIcon={true}
                         contentColor={'var(--070-black)'}>
                        {localizations.contactsScheduleInfo2}
                    </Row>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableName1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableContent1}
                                </Row>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableName2}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader1-font-size)'}
                                     contentLineHeight={'var(--subheader1-line-height)'}>
                                    {localizations.contactsScheduleTableContent2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'45px 0'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.contactsTitle}
                    </Headline>
                </div>
                <div className={styles.Table}>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName1}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent1}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName2}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent2}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName3}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent3}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName4}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent4}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName5}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent5}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName6}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent6}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName7}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            {localizations.contactsTableRowContent7}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName8}
                        </Headline>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            <a className={styles.Link}
                               href={`tel:${process.env.REACT_APP_ACSK_PHONE_1}`}>
                                {localizations.contactsTableRowContent8Part1}
                            </a>
                        </Row>
                        <Row disabledIcon={true}
                             contentColor={'var(--070-black)'}>
                            <a className={styles.Link}
                               href={`tel:${process.env.REACT_APP_ACSK_PHONE_2}`}>
                                {localizations.contactsTableRowContent8Part2}
                            </a>
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName9}
                        </Headline>
                        <Row disabledIcon={true}>
                            <Link className={styles.Link}
                                  to={pagePaths.MAIN_PAGE_PATH}>
                                {localizations.contactsTableRowContent9}
                            </Link>
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Headline contentMargin={'0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                  contentFontSize={'var(--body2-bold-font-size)'}
                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                            {localizations.contactsTableRowName10}
                        </Headline>
                        <Row disabledIcon={true}>
                            <a className={styles.Link}
                               href={`mailto:${process.env.REACT_APP_ACSK_MAIL}`}>
                                {localizations.contactsTableRowContent10}
                            </a>
                        </Row>
                    </div>
                </div>
                <div className={styles.BottomRow}>
                    <Row topLine={true}
                         disabledIcon={true}
                         lineMaxWidth={'1000px'}/>
                </div>
                <div className={styles.Schedule}>
                    <Headline contentFontWeight={'var(--headline3-font-weight)'}
                              contentFontSize={'var(--headline3-font-size)'}
                              contentLineHeight={'var(--headline3-line-height)'}>
                        {localizations.contactsScheduleInfo1}
                    </Headline>
                    <Row disabledIcon={true}
                         contentColor={'var(--070-black)'}>
                        {localizations.contactsScheduleInfo2}
                    </Row>
                    <div className={styles.Table}>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader2-font-size)'}
                                     contentLineHeight={'var(--subheader2-line-height)'}>
                                    {localizations.contactsScheduleTableName1}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader2-font-size)'}
                                     contentLineHeight={'var(--subheader2-line-height)'}>
                                    {localizations.contactsScheduleTableContent1}
                                </Row>
                            </div>
                        </div>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Row}>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader2-font-size)'}
                                     contentLineHeight={'var(--subheader2-line-height)'}>
                                    {localizations.contactsScheduleTableName2}
                                </Row>
                            </div>
                            <div className={styles.Element}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--subheader2-font-size)'}
                                     contentLineHeight={'var(--subheader2-line-height)'}>
                                    {localizations.contactsScheduleTableContent2}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

export default Contacts;
