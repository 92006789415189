import React from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Header.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as AcskLogo} from './acskLogo.svg';
import * as pagePaths from '../../paths/pagePaths';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Dropdown from '../Dropdown/Dropdown';
import Menu from '../Menu/Menu';
import TechWorks from '../TechWorks/TechWorks';

function Header(props) {
    const {languages, activeLanguage = languages[0], techWorks, isAuthorized, authorizedUser} = props;
    const {setLanguage, checkAuthorizedToken, login, logout} = props;
    return (
        <div className={styles.Header}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.Top}>
                    <Link className={styles.TitleLink}
                          to={pagePaths.MAIN_PAGE_PATH}>
                        <div className={styles.Title}>
                            <AcskLogo className={styles.AcskLogo}/>
                            <div className={styles.Text}>
                                {localizations.headerTitle}
                            </div>
                        </div>
                    </Link>
                    <div className={styles.Links}>
                        <Link className={styles.Link}
                              to={pagePaths.CERTIFICATES_PAGE_PATH}>
                            {localizations.headerCertificatesPageLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.NORMATIVE_BASE_PAGE_PATH}>
                            {localizations.headerNormativeBasePageLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.DOCUMENTS_PAGE_PATH}>
                            {localizations.headerDocumentsPageLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.SMARTID_PAGE_PATH}>
                            {localizations.headerSmartIDPageLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.ONLINE_SERVICES_PAGE_PATH}>
                            {localizations.headerOnlineServicesPageLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.FREQUENTLY_ASKED_QUESTIONS_PAGE_PATH}>
                            {localizations.headerFrequentlyAskedQuestionsLink}
                        </Link>
                        <Link className={styles.Link}
                              to={pagePaths.CONTACTS_PAGE_PATH}>
                            {localizations.headerContactsPageLink}
                        </Link>
                    </div>
                    <div className={styles.Control}>
                        <Switch>
                            <Route path={pagePaths.MAIN_PAGE_PATH}>
                                <Dropdown values={languages}
                                          activeValue={activeLanguage}
                                          setValue={(value) => {
                                              setLanguage(value);
                                              checkAuthorizedToken();
                                          }}
                                          optionBackgroundColor={'var(--purple)'}/>
                            </Route>
                            <Route path={pagePaths.SMARTID_PAGE_PATH}>
                                <Dropdown values={languages}
                                          activeValue={activeLanguage}
                                          setValue={(value) => {
                                              setLanguage(value);
                                              checkAuthorizedToken();
                                          }}
                                          optionBackgroundColor={'var(--purple)'}/>
                            </Route>
                            <Route path={pagePaths.ROOT_PAGE_PATH}>
                                <Dropdown values={languages}
                                          activeValue={activeLanguage}
                                          setValue={(value) => {
                                              setLanguage(value);
                                              checkAuthorizedToken();
                                          }}
                                          optionBackgroundColor={'var(--white)'}/>
                            </Route>
                        </Switch>
                        <Menu checkAuthorizedToken={checkAuthorizedToken}
                              isAuthorized={isAuthorized}
                              languages={languages}
                              activeLanguage={activeLanguage}
                              setLanguage={setLanguage}
                              authorizedUser={authorizedUser}
                              login={login}
                              logout={logout}/>
                    </div>
                </div>
                {
                    techWorks?.enable && <TechWorks techWorks={techWorks}/>
                }
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.Top}>
                    <Link className={styles.TitleLink}
                          to={pagePaths.MAIN_PAGE_PATH}>
                        <div className={styles.Title}>
                            <AcskLogo className={styles.AcskLogo}/>
                            <div className={styles.Text}>
                                {localizations.headerTitle}
                            </div>
                        </div>
                    </Link>
                    <Menu checkAuthorizedToken={checkAuthorizedToken}
                          isAuthorized={isAuthorized}
                          languages={languages}
                          activeLanguage={activeLanguage}
                          setLanguage={setLanguage}
                          authorizedUser={authorizedUser}
                          login={login}
                          logout={logout}/>
                </div>
                {
                    techWorks?.enable && <TechWorks techWorks={techWorks}/>
                }
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.Top}>
                    <Link className={styles.TitleLink}
                          to={pagePaths.MAIN_PAGE_PATH}>
                        <div className={styles.Title}>
                            <AcskLogo className={styles.AcskLogo}/>
                            <div className={styles.Text}>
                                {localizations.headerTitle}
                            </div>
                        </div>
                    </Link>
                    <Menu checkAuthorizedToken={checkAuthorizedToken}
                          isAuthorized={isAuthorized}
                          languages={languages}
                          activeLanguage={activeLanguage}
                          setLanguage={setLanguage}
                          authorizedUser={authorizedUser}
                          login={login}
                          logout={logout}/>
                </div>
                {
                    techWorks?.enable && <TechWorks techWorks={techWorks}/>
                }
            </MobileOnlyView>
        </div>
    );
}

Header.propTypes = {
    languages: PropTypes.array.isRequired,
    techWorks: PropTypes.object.isRequired,
    setLanguage: PropTypes.func.isRequired,
    checkAuthorizedToken: PropTypes.func.isRequired,
    activeLanguage: PropTypes.string,
    isAuthorized: PropTypes.bool,
    authorizedUser: PropTypes.string,
    login: PropTypes.func,
    logout: PropTypes.func,
}

export default Header;
