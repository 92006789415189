import React from 'react';
import styles from './FrequentlyAskedQuestions.module.css';
import localizations from '../../localizations/localizations';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function FrequentlyAskedQuestions() {
    return (
        <div className={styles.FrequentlyAskedQuestions}>
            <BrowserView viewClassName={styles.Browser}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0 30px'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.frequentlyAskedQuestionsTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion1}>
                        {localizations.frequentlyAskedQuestionsAnswer1}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion2}>
                        {localizations.frequentlyAskedQuestionsAnswer2}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion3}>
                        {localizations.frequentlyAskedQuestionsAnswer3}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion4}>
                        {localizations.frequentlyAskedQuestionsAnswer4}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion5}>
                        {localizations.frequentlyAskedQuestionsAnswer5}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion6}>
                        {localizations.frequentlyAskedQuestionsAnswer6}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion7}>
                        {localizations.frequentlyAskedQuestionsAnswer7}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion8}>
                        {localizations.frequentlyAskedQuestionsAnswer8}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion9}>
                        {localizations.frequentlyAskedQuestionsAnswer9}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion10}>
                        {localizations.frequentlyAskedQuestionsAnswer10}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion11}>
                        {localizations.frequentlyAskedQuestionsAnswer11}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion12}>
                        {localizations.frequentlyAskedQuestionsAnswer12}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion13}>
                        {localizations.frequentlyAskedQuestionsAnswer13}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion14}>
                        {localizations.frequentlyAskedQuestionsAnswer14}
                    </Row>
                    <Row contentMargin={'30px 0 0'}
                         collapse={localizations.frequentlyAskedQuestionsQuestion15}>
                        {localizations.frequentlyAskedQuestionsAnswer15}
                    </Row>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'60px 0 30px'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.frequentlyAskedQuestionsTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion1}>
                        {localizations.frequentlyAskedQuestionsAnswer1}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion2}>
                        {localizations.frequentlyAskedQuestionsAnswer2}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion3}>
                        {localizations.frequentlyAskedQuestionsAnswer3}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion4}>
                        {localizations.frequentlyAskedQuestionsAnswer4}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion5}>
                        {localizations.frequentlyAskedQuestionsAnswer5}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion6}>
                        {localizations.frequentlyAskedQuestionsAnswer6}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion7}>
                        {localizations.frequentlyAskedQuestionsAnswer7}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion8}>
                        {localizations.frequentlyAskedQuestionsAnswer8}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion9}>
                        {localizations.frequentlyAskedQuestionsAnswer9}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion10}>
                        {localizations.frequentlyAskedQuestionsAnswer10}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion11}>
                        {localizations.frequentlyAskedQuestionsAnswer11}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion12}>
                        {localizations.frequentlyAskedQuestionsAnswer12}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion13}>
                        {localizations.frequentlyAskedQuestionsAnswer13}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion14}>
                        {localizations.frequentlyAskedQuestionsAnswer14}
                    </Row>
                    <Row contentMargin={'30px 0 0'}
                         collapse={localizations.frequentlyAskedQuestionsQuestion15}>
                        {localizations.frequentlyAskedQuestionsAnswer15}
                    </Row>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div className={styles.TopRow}>
                    <Row topLine={true}
                         disabledIcon={true}/>
                </div>
                <div className={styles.Title}>
                    <Headline contentMargin={'45px 0 15px'}
                              contentFontWeight={'bold'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.frequentlyAskedQuestionsTitle}
                    </Headline>
                </div>
                <div className={styles.Content}>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion1}>
                        {localizations.frequentlyAskedQuestionsAnswer1}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion2}>
                        {localizations.frequentlyAskedQuestionsAnswer2}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion3}>
                        {localizations.frequentlyAskedQuestionsAnswer3}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion4}>
                        {localizations.frequentlyAskedQuestionsAnswer4}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion5}>
                        {localizations.frequentlyAskedQuestionsAnswer5}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion6}>
                        {localizations.frequentlyAskedQuestionsAnswer6}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion7}>
                        {localizations.frequentlyAskedQuestionsAnswer7}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion8}>
                        {localizations.frequentlyAskedQuestionsAnswer8}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion9}>
                        {localizations.frequentlyAskedQuestionsAnswer9}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion10}>
                        {localizations.frequentlyAskedQuestionsAnswer10}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion11}>
                        {localizations.frequentlyAskedQuestionsAnswer11}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion12}>
                        {localizations.frequentlyAskedQuestionsAnswer12}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion13}>
                        {localizations.frequentlyAskedQuestionsAnswer13}
                    </Row>
                    <Row collapse={localizations.frequentlyAskedQuestionsQuestion14}>
                        {localizations.frequentlyAskedQuestionsAnswer14}
                    </Row>
                    <Row contentMargin={'30px 0 0'}
                         collapse={localizations.frequentlyAskedQuestionsQuestion15}>
                        {localizations.frequentlyAskedQuestionsAnswer15}
                    </Row>
                </div>
            </MobileOnlyView>
        </div>
    )
}

export default FrequentlyAskedQuestions;
